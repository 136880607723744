import React, { useRef, useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { CardWrapper } from 'components/CardWrapper/CardWrapper';
import { useDeleteClientPhotoMutation, useDeleteDocumentPhotoMutation, useUploadFilesMutation } from 'services/api';
import { FileDropzone } from './components/FileDropzone';
import { FilePreview } from './components/FilePreview';
import { usePhotoUpload } from './hooks/usePhotoUpload';
import { normalizePath, formatFileName, createFileWithNewName, FileType } from './utils/fileUtils';
import { PhotoFormProps } from './types';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';
import { Icon } from '@iconify/react';
import ModalWithChildren from 'components/ui/ModalWithChildren/ui/ModalWithChildren';
import YellowClockIcon from 'assets/icons/clock_yellow.svg';

import styles from './PhotoForm.module.scss';

export const PhotoForm: React.FC<PhotoFormProps> = ({
    setIsFormValid,
    onFormSubmit,
    serverData,
    requestId,
    refetch
}) => {
    const methods = useForm();
    const { handleSubmit, formState: { isValid } } = methods;

    const [documentFileUrls, setDocumentFileUrls] = useState<string[]>([]);
    console.log("🚀 ~ file: PhotoForm.tsx:27 ~ documentFileUrls:", documentFileUrls)
    const [clientFileUrls, setClientFileUrls] = useState<string[]>([]);
    const [documentFiles, setDocumentFiles] = useState<File[]>([]);
    console.log("🚀 ~ file: PhotoForm.tsx:30 ~ documentFiles:", documentFiles)
    const [clientFiles, setClientFiles] = useState<File[]>([]);
    const [isSingleDocumentFile, setIsSingleDocumentFile] = useState(false);
    console.log("🚀 ~ file: PhotoForm.tsx:33 ~ isSingleDocumentFile:", isSingleDocumentFile)
    const [isFilesReady, setIsFilesReady] = useState(false);
    console.log("🚀 ~ file: PhotoForm.tsx:32 ~ isFilesReady:", isFilesReady)
    const [isDocumentUploadSuccess, setDocumentUploadSuccess] = useState(false);
    const [isClientUploadSuccess, setClientUploadSuccess] = useState(false);

    const [uploadFiles, { isLoading }] = useUploadFilesMutation();
    const [deleteDocumentPhoto] = useDeleteDocumentPhotoMutation();
    const [deleteClientPhoto] = useDeleteClientPhotoMutation();

    const documentPhotosInputRef = useRef<HTMLInputElement | null>(null);
    const clientPhotoInputRef = useRef<HTMLInputElement | null>(null);

    const isDocumentDropzoneShouldBeDisabled = isLoading || (documentFiles.length === 1 && isSingleDocumentFile) || (documentFiles.length === 2 && !isSingleDocumentFile);
    const isClientDropzoneShouldBeDisabled = isLoading || clientFiles.length === 1;

    const {
        error: documentError,
        onDrop: onDropDocument,
        isModalOpen,
        handleModalConfirm,
        handleModalClose
    } = usePhotoUpload({
        maxFiles: 2,
        isSingleFile: isSingleDocumentFile,
        setIsSingleFile: setIsSingleDocumentFile,
        currentFiles: documentFiles,
        setFiles: setDocumentFiles,
        type: 'document'
    });

    const {
        error: clientError,
        onDrop: onDropClient
    } = usePhotoUpload({
        maxFiles: 1,
        currentFiles: clientFiles,
        setFiles: setClientFiles,
        type: 'client'
    });

    useEffect(() => {
        if (serverData) {
            setDocumentFileUrls(serverData.documentFiles || []);
            setClientFileUrls(serverData.clientFiles || []);
            setDocumentUploadSuccess(serverData.documentFiles ? serverData.documentFiles.length >= 2 : false);
            setClientUploadSuccess(serverData.clientFiles ? serverData.clientFiles.length > 0 : false);
        }
    }, [serverData]);

    useEffect(() => {
        if (isClientUploadSuccess || isDocumentUploadSuccess) {
            refetch();
        }
    }, [isClientUploadSuccess, isDocumentUploadSuccess, refetch]);

    // Проверка валидности документов: либо 2 отдельных файла, либо 1 общий
    const isDocumentsValid = (documentFileUrls.length + documentFiles.length === 2) ||
        (isSingleDocumentFile && (documentFileUrls.length + documentFiles.length === 1));
    const isClientPhotosValid = clientFileUrls.length + clientFiles.length > 0;
    const hasNoErrors = !documentError && !clientError;

    useEffect(() => {
        const isValid = isDocumentsValid && isClientPhotosValid && hasNoErrors;
        const isUploadComplete = isDocumentUploadSuccess && isClientUploadSuccess;
        setIsFilesReady(isValid);
        setIsFormValid(isValid && isUploadComplete);
    }, [
        documentFileUrls,
        clientFileUrls,
        documentFiles,
        clientFiles,
        documentError,
        clientError,
        setIsFormValid,
        isDocumentsValid,
        isClientPhotosValid,
        hasNoErrors,
        isDocumentUploadSuccess,
        isClientUploadSuccess
    ]);

    const handleDeleteDocumentFile = async (index: number) => {
        if (index < documentFileUrls.length) {
            const fileUrl = documentFileUrls[index];
            try {
                await deleteDocumentPhoto({
                    id: requestId,
                    photoPath: normalizePath(fileUrl)
                }).unwrap();
                setDocumentFileUrls(prevUrls => prevUrls.filter((_, i) => i !== index));
            } catch (error) {
                console.error('Ошибка при удалении фото документов с сервера:', error);
            }
        } else {
            setDocumentFiles(prevFiles =>
                prevFiles.filter((_, i) => i !== index - documentFileUrls.length)
            );
        }

        // Сброс состояния isSingleDocumentFile, если файл был удален
        if (isSingleDocumentFile) {
            setIsSingleDocumentFile(false);
        }
    };

    const handleDeleteClientFile = async (index: number) => {
        if (index < clientFileUrls.length) {
            const fileUrl = clientFileUrls[index];
            try {
                await deleteClientPhoto({
                    id: requestId,
                    photoPath: normalizePath(fileUrl)
                }).unwrap();
                setClientFileUrls(prevUrls => prevUrls.filter((_, i) => i !== index));
            } catch (error) {
                console.error('Ошибка при удалении фото клиента с сервера:', error);
            }
        } else {
            setClientFiles(prevFiles =>
                prevFiles.filter((_, i) => i !== index - clientFileUrls.length)
            );
        }
    };

    const onSubmit = async (formData: any) => {
        if (!isFilesReady || !requestId) return;

        const formDataToSend = new FormData();
        formDataToSend.append('requestId', requestId);

        // Обработка документов
        if (isSingleDocumentFile) {
            // Если один файл для документа
            const [file] = documentFiles;
            if (file) {
                const newName = formatFileName(requestId, 'DUL', file);
                const renamedFile = createFileWithNewName(file, newName);
                formDataToSend.append('documentFiles', renamedFile);
            }
        } else {
            // Если два файла для документа
            documentFiles.forEach((file, index) => {
                const type: FileType = index === 0 ? 'DUL1' : 'DUL2';
                const newName = formatFileName(requestId, type, file);
                const renamedFile = createFileWithNewName(file, newName);
                formDataToSend.append('documentFiles', renamedFile);
            });
        }

        // Обработка фото клиента
        clientFiles.forEach(file => {
            const newName = formatFileName(requestId, 'PHOTO', file);
            const renamedFile = createFileWithNewName(file, newName);
            formDataToSend.append('clientFiles', renamedFile);
        });

        try {
            const response = await uploadFiles({
                formData: formDataToSend,
                id: requestId
            }).unwrap();

            if (response.documentPhotosUrl) {
                setDocumentUploadSuccess(true);
                setDocumentFiles([]);
            }

            if (response.clientPhotoUrl) {
                setClientUploadSuccess(true);
                setClientFiles([]);
            }

            refetch();
            onFormSubmit(formData);
        } catch (error) {
            console.error('Ошибка при загрузке файлов:', error);
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                <div className={styles.flexRow}>
                    <CardWrapper title="Фото документов">

                        {(isDocumentUploadSuccess || documentFileUrls.length >= 1) ? (
                            <div className={styles.successBlock}>
                                <Icon icon="mdi:check-circle" color="#4CAF50" width="24px" />
                                <span>Фото успешно загружены</span>
                            </div>
                        ) : (
                            <>
                                <div className={styles.photoSubtitleWrapper}>
                                    <div>Загрузите фото или сканы паспорта:</div>
                                    <ul className={styles.ulList} >
                                        <li>Разворот 2-3 страницы</li>
                                        <li>Страница актуальной прописки</li>
                                    </ul>
                                </div>
                                <FileDropzone
                                    onDrop={onDropDocument}
                                    inputRef={documentPhotosInputRef}
                                    error={documentError}
                                    isDisabled={isDocumentDropzoneShouldBeDisabled}
                                    type="document"
                                    maxFiles={2}
                                />
                                <FilePreview
                                    files={[...documentFileUrls, ...documentFiles]}
                                    onDelete={handleDeleteDocumentFile}
                                    type="document"
                                />
                            </>
                        )}

                    </CardWrapper>

                    <CardWrapper title="Фото с клиентом">

                        {(isClientUploadSuccess || clientFileUrls.length > 0) ? (
                            <div className={styles.successBlock}>
                                <Icon icon="mdi:check-circle" color="#4CAF50" width="24px" />
                                <span>Фото успешно загружено</span>
                            </div>
                        ) : (
                            <>
                                <div className={styles.photoSubtitleWrapper}>
                                    <div>Загрузите фото клиента:</div>
                                    <ul className={styles.ulList} >
                                        <li>Лицо клиента должно быть открыто, без посторонних предметов</li>
                                        <li>Выражение лица — нейтральное</li>
                                    </ul>
                                </div>
                                <FileDropzone
                                    onDrop={onDropClient}
                                    inputRef={clientPhotoInputRef}
                                    error={clientError}
                                    isDisabled={isClientDropzoneShouldBeDisabled}
                                    type="client"
                                    maxFiles={1}
                                />
                                <FilePreview
                                    files={[...clientFileUrls, ...clientFiles]}
                                    onDelete={handleDeleteClientFile}
                                    type="client"
                                />
                            </>
                        )}

                    </CardWrapper>
                </div>
                <ModalWithChildren
                    isOpen={isModalOpen}
                    onClose={handleModalClose}
                // title="Загрузка файла"
                >
                    <div className={styles.modalContent}>
                        <img src={YellowClockIcon} alt="Yellow Clock Icon" width={"28px"} />
                        <p className={styles.modalText}>Вы загружаете один файл. Это общий файл, содержащий оба разворота паспорта?</p>
                        <div className={styles.modalButtons}>
                            <ButtonUI label="Нет" variant="main2" onClick={handleModalClose} width='80px' />
                            <ButtonUI label="Да" variant="main" onClick={handleModalConfirm} width='80px' />
                        </div>
                    </div>
                </ModalWithChildren>
                {(!isDocumentUploadSuccess || !isClientUploadSuccess) && (
                    <div className={styles.submitButtonContainer}>
                        <ButtonUI
                            variant="secondary"
                            label={isLoading ? 'Отправка...' : 'Отправить'}
                            type="submit"
                            fullWidth
                            disabled={!isFilesReady || isLoading}
                        />
                    </div>
                )}

            </form>
        </FormProvider>
    );
};
