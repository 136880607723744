import React from 'react';

interface IconProps {
  active?: boolean;
  hoveredColor?: string;
  defaultColor?: string;
  isHovered?: boolean;
}

const HeaderSettingsIcon: React.FC<IconProps> = ({ 
  active, 
  hoveredColor = '#71BE81', 
  defaultColor = '#AAAABB',
  isHovered = false
}) => {
  const fillColor = active ? hoveredColor : (isHovered ? hoveredColor : defaultColor);

  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.0016 12.051C12.9252 12.051 12.0526 12.9236 12.0526 14C12.0526 15.0764 12.9252 15.949 14.0016 15.949C15.078 15.949 15.9506 15.0764 15.9506 14C15.9506 12.9236 15.078 12.051 14.0016 12.051Z" fill={fillColor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M21.8016 14.6838L20.4724 15.1713C20.2722 15.2441 20.1214 15.4111 20.0674 15.6172C19.9283 16.1489 19.7151 16.665 19.4324 17.1505C19.3258 17.3338 19.3147 17.5581 19.4044 17.7511L19.9998 19.0362L19.0372 19.9988L17.7521 19.4034C17.5604 19.3137 17.3361 19.3254 17.1515 19.4314C16.666 19.7141 16.1499 19.9273 15.6182 20.0664C15.4128 20.1204 15.2457 20.2712 15.1723 20.4714L14.6854 21.8H13.3178L12.8303 20.4707C12.7569 20.2705 12.5905 20.1197 12.3838 20.0658C11.8527 19.9273 11.3366 19.7135 10.8498 19.4307C10.6665 19.3235 10.4422 19.3131 10.2498 19.4028L8.96476 19.9982L8.00146 19.0355L8.59751 17.7505C8.68656 17.5581 8.67681 17.3338 8.57021 17.1505C8.28681 16.6624 8.07296 16.1463 7.93516 15.6172C7.88121 15.4111 7.72976 15.2441 7.52956 15.1706L6.20161 14.6838V13.3162L7.53021 12.8287C7.73041 12.7552 7.88186 12.5882 7.93581 12.3821C8.07361 11.853 8.28746 11.3369 8.57086 10.8481C8.67746 10.6648 8.68786 10.4412 8.59816 10.2482L8.00276 8.96441L8.96606 8.00111L10.2505 8.59651C10.4422 8.68621 10.6671 8.67581 10.8498 8.56921C11.3386 8.28581 11.8547 8.07196 12.3838 7.93416C12.5898 7.88021 12.7569 7.72876 12.8303 7.52856L13.3178 6.19996H14.6854L15.1729 7.52856C15.2457 7.72876 15.4128 7.88021 15.6195 7.93416C16.1486 8.07196 16.6647 8.28581 17.1528 8.56921C17.3368 8.67581 17.5604 8.68556 17.7528 8.59651L19.0378 8.00111L20.0005 8.96441L19.4051 10.2495C19.3154 10.4419 19.3264 10.6661 19.433 10.8494C19.7158 11.3363 19.9296 11.8517 20.0681 12.3834C20.122 12.5895 20.2728 12.7565 20.473 12.83L21.8016 13.3162V14.6838ZM10.7506 14C10.7506 12.2045 12.2061 10.749 14.0016 10.749C15.7971 10.749 17.2526 12.2045 17.2526 14C17.2526 15.7954 15.7971 17.251 14.0016 17.251C12.2061 17.251 10.7506 15.7954 10.7506 14Z" fill={fillColor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.0032 27.9999C21.7352 27.9999 28.0032 21.7319 28.0032 13.9999C28.0032 6.26795 21.7352 -6.10352e-05 14.0032 -6.10352e-05C6.27119 -6.10352e-05 0.00317383 6.26795 0.00317383 13.9999C0.00317383 21.7319 6.27119 27.9999 14.0032 27.9999ZM22.6759 12.2521L21.2303 11.7224C21.099 11.3025 20.9287 10.8923 20.7226 10.4978L21.3707 9.09961C21.4857 8.85261 21.4337 8.55946 21.2407 8.36706L19.6352 6.76156C19.4428 6.56786 19.1496 6.51651 18.902 6.63156L17.5045 7.27961C17.1086 7.07291 16.6985 6.90326 16.2792 6.77196L15.7495 5.32701C15.6552 5.07026 15.4121 4.89996 15.1391 4.89996H12.8641C12.5918 4.89996 12.348 5.07026 12.2538 5.32636L11.724 6.77131C11.3054 6.90261 10.8953 7.07226 10.4994 7.27896L9.10061 6.63091C8.85361 6.51586 8.56046 6.56786 8.36806 6.76091L6.76256 8.36641C6.56951 8.55946 6.51751 8.85196 6.63256 9.09961L7.28126 10.4971C7.07456 10.893 6.90426 11.3031 6.77361 11.7217L5.32801 12.2515C5.07191 12.3464 4.90161 12.5901 4.90161 12.8625V15.1375C4.90161 15.4105 5.07191 15.6536 5.32801 15.7472L6.77296 16.2769C6.90426 16.6968 7.07391 17.1057 7.28061 17.5022L6.63191 18.8997C6.51686 19.1473 6.56886 19.4398 6.76191 19.6329L8.36741 21.2384C8.56046 21.4314 8.85361 21.4834 9.09996 21.3684L10.4981 20.7203C10.8927 20.9264 11.3028 21.0967 11.7227 21.228L12.2525 22.6736C12.348 22.9297 12.5918 23.1 12.8641 23.1H15.1391C15.4121 23.1 15.6552 22.9297 15.7488 22.6742L16.2786 21.2286C16.6985 21.0973 17.1086 20.927 17.5038 20.721L18.9013 21.369C19.149 21.4841 19.4421 21.4321 19.6345 21.239L21.24 19.6335C21.4331 19.4405 21.4851 19.148 21.37 18.9003L20.722 17.5028C20.928 17.1083 21.0983 16.6981 21.2296 16.2776L22.6752 15.7478C22.9313 15.6536 23.1016 15.4105 23.1016 15.1375V12.8625C23.1016 12.5901 22.9313 12.3464 22.6759 12.2521Z" fill={fillColor} />
    </svg>
  );
};

export default HeaderSettingsIcon;
