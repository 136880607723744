import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PhotoForm } from 'components/PhotoForm/PhotoForm';
import {
    useGetCardRequestByIdQuery,
    useUpdateRequestStepMutation,
    useRequestSmsCodeMutation
} from 'services/api';

interface Step4Props {
    setIsFormValid: (isValid: boolean) => void;
    onFormSubmit: (data: any) => void;
    requestId: string | null;
    setRequestId: (requestId: any) => void;
}

const Step4: React.FC<Step4Props> = ({ setIsFormValid, onFormSubmit, requestId, setRequestId }) => {
    // console.log(" ~ file: Step4.tsx:18 ~ requestId:", requestId)
    const { data: cardRequestData, refetch } = useGetCardRequestByIdQuery(requestId);
    const [updateRequestStep] = useUpdateRequestStepMutation();
    const [requestSmsCode] = useRequestSmsCodeMutation();

    const methods = useForm({
        mode: 'onChange',
        defaultValues: cardRequestData || {},
    });

    const { reset } = methods;

    useEffect(() => {
        if (cardRequestData) {
            reset(cardRequestData);
        }
    }, [cardRequestData, reset]);

    useEffect(() => {
        refetch();
    }, [refetch]);

    const handleFormValidityChange = (isValid: boolean) => {
        setIsFormValid(isValid);
    };

    const [isSendingSms, setIsSendingSms] = useState(false);

    const onSubmit = async (data: any) => {

        if (!requestId) {
            console.error('No requestId available');
            return;
        }

        try {
            // Обновляем данные шага
            await updateRequestStep({ id: requestId, step: 4, data }).unwrap();

            // Получаем актуальные данные из базы
            const updatedData = await refetch();

            // Проверяем статус верификации
            if (updatedData.data?.phoneVerified) {
                onFormSubmit(data);
            } else {

                // Защита от повторных вызовов
                if (isSendingSms) {
                    console.log(" ~ Step4 ~ SMS sending already in progress");
                    return;
                }

                setIsSendingSms(true);
                try {
                    const result = await requestSmsCode(requestId).unwrap();

                    if (!result.smsResult?.includes('ERROR')) {
                        onFormSubmit(data);
                    } else {
                        console.error(" ~ Step4 ~ SMS sending failed:", result.smsResult);
                    }
                } finally {
                    setIsSendingSms(false);
                }
            }
        } catch (error) {
            setIsSendingSms(false);
        }
    };

    return (
        <PhotoForm
            setIsFormValid={handleFormValidityChange}
            onFormSubmit={onSubmit}
            serverData={{
                documentFiles: cardRequestData?.documentPhotos || [],
                clientFiles: cardRequestData?.clientPhoto ? [cardRequestData.clientPhoto] : [],
            }}
            requestId={requestId || undefined}
            refetch={refetch}
        />
    );
};

export default Step4;
