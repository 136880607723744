import { useState, useCallback } from 'react';
import { validateFileType, MAX_FILE_SIZE, OPTIMAL_FILE_SIZE, getFileExtension } from '../utils/fileUtils';

interface UsePhotoUploadProps {
    maxFiles: number;
    isSingleFile?: boolean;
    setIsSingleFile?: (value: boolean) => void;
    currentFiles: File[];
    setFiles: (files: File[]) => void;
    type: 'document' | 'client';
}

export const usePhotoUpload = ({
    maxFiles,
    isSingleFile,
    setIsSingleFile,
    currentFiles,
    setFiles,
    type
}: UsePhotoUploadProps) => {
    const [error, setError] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pendingFiles, setPendingFiles] = useState<File[]>([]);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const validFiles = acceptedFiles.filter(file => {
            const isValid = validateFileType(file);
            const isSizeValid = file.size <= MAX_FILE_SIZE;

            if (!isValid) {
                setError('Ошибка. Поддерживаются только файлы JPG, PNG и PDF.');
                return false;
            }
            if (!isSizeValid) {
                setError('Размер файла не должен превышать 100MB.');
                return false;
            }
            if (file.size > OPTIMAL_FILE_SIZE) {
                console.warn('Размер файла превышает рекомендуемые 10MB');
            }
            return true;
        });

        if (validFiles.length === 0) return;

        if (type === 'document') {
            // Проверяем, что все файлы имеют одинаковое расширение
            const firstExtension = getFileExtension(validFiles[0]).toLowerCase();
            const allSameExtension = validFiles.every(file => 
                getFileExtension(file).toLowerCase() === firstExtension
            );

            if (!allSameExtension) {
                setError('Все файлы паспорта должны быть одного формата.');
                return;
            }

            // Если уже есть загруженные файлы, проверяем их расширение
            if (currentFiles.length > 0) {
                const existingExtension = getFileExtension(currentFiles[0]).toLowerCase();
                if (existingExtension !== firstExtension) {
                    setError('Новый файл должен быть того же формата, что и уже загруженные файлы.');
                    return;
                }
            }

            // Если загружается один файл, спрашиваем пользователя о типе загрузки
            if (validFiles.length === 1 && currentFiles.length === 0 && setIsSingleFile) {
                setPendingFiles(validFiles);
                setIsModalOpen(true);
                return;
            }
        }

        const totalFiles = currentFiles.length + validFiles.length;
        const effectiveMaxFiles = type === 'document' && isSingleFile ? 1 : maxFiles;

        if (totalFiles > effectiveMaxFiles) {
            setError(`Можно загрузить ${effectiveMaxFiles === 1 ? 'только один файл' : `максимум ${effectiveMaxFiles} файла`}.`);
            return;
        }

        setFiles([...currentFiles, ...validFiles]);
        setError(null);
    }, [currentFiles, maxFiles, isSingleFile, setIsSingleFile, type, setFiles]);

    const handleModalConfirm = () => {
        setIsSingleFile?.(true);
        setFiles([...currentFiles, ...pendingFiles]);
        setIsModalOpen(false);
        setError(null);
    };

    const handleModalClose = () => {
        setIsSingleFile?.(false);
        setFiles([...currentFiles, ...pendingFiles]);
        setIsModalOpen(false);
        setError(null);
    };

    return {
        error,
        setError,
        onDrop,
        isModalOpen,
        handleModalConfirm,
        handleModalClose
    };
};
