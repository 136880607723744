import React from 'react';
import { useDropzone } from 'react-dropzone';
// import { Icon } from '@iconify/react';
// import ImgIcon from 'assets/icons/ImgIcon';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';
import { acceptedFileTypes } from '../utils/fileUtils';
import styles from '../PhotoForm.module.scss';

interface FileDropzoneProps {
    onDrop: (files: File[]) => void;
    inputRef: React.RefObject<HTMLInputElement>;
    error: string | null;
    isDisabled?: boolean;
    type: 'document' | 'client';
    maxFiles: number;
}

export const FileDropzone: React.FC<FileDropzoneProps> = ({
    onDrop,
    inputRef,
    error,
    isDisabled,
    type,
    maxFiles
}) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        noClick: true,
        noKeyboard: true,
        maxFiles,
        multiple: maxFiles > 1,
        accept: acceptedFileTypes,
        preventDropOnDocument: true,
        noDragEventsBubbling: true,
        onFileDialogOpen: () => {
            // Сбрасываем значение input при открытии диалога
            if (inputRef.current) {
                inputRef.current.value = '';
            }
        }
    });

    const handleUploadClick = () => {
        // Сбрасываем значение input перед открытием
        if (inputRef.current) {
            inputRef.current.value = '';
            inputRef.current.click();
        }
    };

    return (
        <div
            {...getRootProps()}
            className={`${styles.dropzone} ${isDragActive ? styles.active : ''} ${error ? styles.error : ''}`}
        >
            <input {...getInputProps()} ref={inputRef} />
            <div className={styles.dropzoneContent}>
                <ButtonUI
                    onClick={handleUploadClick}
                    disabled={isDisabled}
                    className={styles.uploadButton}
                    label={'Загрузить изображения'}
                    variant={'main2'}
                />
                <div className={styles.dropzoneText}>
                    <span className={styles.dropzoneSubtext}>
                        Или перетащите изображения сюда
                    </span>
                </div>
            </div>
            {error && <div className={styles.errorMessage}>{error}</div>}
        </div>
    );
};
