import SuccessIcon from 'assets/icons/SuccessIcon';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';

import styles from './RecoveryPasswordDone.module.scss'

const RecoveryPasswordDone = () => {

  return (
    <div className={styles.recoveryContainer}>
      <SuccessIcon />
      <div className={styles.recoverySection}>
        <div className={styles.recoveryContent}>
          <TextUI variant="h1" align='center'>Пароль изменен</TextUI>
          <TextUI variant="bodyL" align='center'>Ваш пароль успешно изменен. Теперь вы можете использовать новый
            пароль для входа.</TextUI>
        </div>
        <ButtonUI label="Войти" variant="main" to='/login' fullWidth />
      </div>
    </div>
  );
};

export default RecoveryPasswordDone;
