import React, { useState, useEffect } from 'react';
import { useCheckCreditCardMutation, useGetBidStatusQuery, useResetOperationStatusMutation, useGeneratePepMutation } from '../../services/api';
import EanCardCheckForm from '../EanCardCheckForm/ui/EanCardCheckForm';
import { useMediaQuery } from 'shared/hooks/UseMediaQuery';

import styles from './Step7.module.scss';

const POLLING_INTERVAL = 3000; // 3 секунды
const MAX_POLLING_ATTEMPTS = 20; // Максимальное количество попыток (1 минута)

interface Step7Props {
    requestId: string | null;
    setIsFormValid?: (isValid: boolean) => void;
    setSkipNextStep?: (skip: boolean) => void;
}

const Step7: React.FC<Step7Props> = ({ requestId, setIsFormValid, setSkipNextStep }) => {
    const [checkCreditCard] = useCheckCreditCardMutation();
    const [resetOperationStatus] = useResetOperationStatusMutation();
    const [generatePep] = useGeneratePepMutation();
    const [isPolling, setIsPolling] = useState(false);
    const [pollingCount, setPollingCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [statusMessage, setStatusMessage] = useState<string | null>(null);
    const [isSuccess, setIsSuccess] = useState(false);
    console.log("🚀 ~ file: Step7.tsx:28 ~ isSuccess:", isSuccess)
    const isMobile = useMediaQuery(768);

    // Получаем начальное значение из базы без поллинга
    const { data: initialStatus } = useGetBidStatusQuery(
        requestId ? { id: requestId, type: 'check_card' } : null,
        {
            pollingInterval: 0, // Отключаем поллинг для начального значения
            skip: hasSubmitted // Пропускаем, если форма была отправлена
        }
    );
    console.log("🚀 ~ file: Step7.tsx:33 ~ initialStatus:", initialStatus)

    // Проверяем начальный статус при загрузке компонента
    useEffect(() => {
        if (initialStatus?.message?.partnerResponseCode === 0) {
            setIsSuccess(true);
            setIsFormValid?.(true);
            setStatusMessage('Проверка карты успешно завершена');
            if (setSkipNextStep) {
                setSkipNextStep(false);
            }
        }
    }, [initialStatus, setIsFormValid, setSkipNextStep]);

    // Запускаем поллинг только после отправки формы
    const { data: pollingStatus } = useGetBidStatusQuery(
        requestId && hasSubmitted ? { id: requestId, type: 'check_card' } : null,
        {
            pollingInterval: isPolling ? POLLING_INTERVAL : 0,
            refetchOnMountOrArgChange: true
        }
    );

    // Используем актуальные данные
    const statusData = hasSubmitted ? pollingStatus : initialStatus;

    // Эффект для обработки статуса
    useEffect(() => {
        if (!statusData) return;

        console.log('Processing status data:', statusData);
        const { partnerResponseCode, partnerResponseMessage, hint } = statusData.message || {};

        if (partnerResponseCode !== undefined) {
            // Устанавливаем состояние на основе полученных данных
            const isSuccessStatus = partnerResponseCode === 0 || partnerResponseCode === 6;
            const isErrorStatus = partnerResponseCode === 1;
            const skipStep8 = partnerResponseCode === 6;

            setIsSuccess(isSuccessStatus);
            setStatusMessage(partnerResponseMessage || '');

            if (isErrorStatus) {
                setErrorMessage(hint || partnerResponseMessage || 'Техническая ошибка');
                setIsPolling(false);
                setIsLoading(false);
                if (setIsFormValid) {
                    setIsFormValid(false);
                }
            } else {
                setErrorMessage(hint || null);
            }

            if (setIsFormValid) {
                setIsFormValid(isSuccessStatus);
            }

            if (setSkipNextStep) {
                setSkipNextStep(skipStep8);
            }

            // Если получен финальный статус, останавливаем поллинг и загрузку
            if (partnerResponseCode !== -1) {
                setIsPolling(false);
                setIsLoading(false);
                setHasSubmitted(true); // Устанавливаем флаг отправки, чтобы показать сообщение
            } else if (hasSubmitted) {
                // Продолжаем поллинг только если форма была отправлена
                setIsPolling(true);
                setStatusMessage('Проверка статуса карты...');
                setPollingCount(prev => prev + 1);
            }
        }

        // Проверяем превышение попыток
        if (hasSubmitted && pollingCount >= MAX_POLLING_ATTEMPTS) {
            setIsPolling(false);
            setIsLoading(false);
            setErrorMessage('Превышено время ожидания ответа. Попробуйте отправить запрос заново.');
        }

        if (statusData?.status === 'success') {
            setIsSuccess(true);
            setIsLoading(false);
            setIsPolling(false);
            setStatusMessage('Проверка карты успешно завершена');
            if (setIsFormValid) {
                setIsFormValid(true);
            }
            // Генерируем PEP после успешной проверки карты
            if (requestId) {
                generatePep(requestId);
            }
        }
    }, [statusData, pollingCount, setIsFormValid, hasSubmitted, setSkipNextStep]);

    // Сброс счетчика при отправке формы
    useEffect(() => {
        if (hasSubmitted) {
            setPollingCount(0);
        }
    }, [hasSubmitted]);

    // Сброс состояний при размонтировании
    useEffect(() => {
        return () => {
            if (setIsFormValid) {
                setIsFormValid(false);
            }
            setIsPolling(false);
            setPollingCount(0);
            setErrorMessage(null);
            setStatusMessage(null);
        };
    }, [setIsFormValid]);

    const handleFormSubmit = async (data: { ean: string }) => {
        try {
            setIsLoading(true);
            setHasSubmitted(true);
            setErrorMessage(null);
            setStatusMessage(null);
            setPollingCount(0);
            if (setIsFormValid) {
                setIsFormValid(false);
            }

            // Отправляем запрос на проверку карты
            const response = await checkCreditCard({ id: requestId, ean: data.ean }).unwrap();
            console.log('Check card response:', response);

            if (response.error) {
                setErrorMessage(response.error);
                setIsSuccess(false);
                setIsLoading(false);
                if (setIsFormValid) {
                    setIsFormValid(false);
                }
            } else {
                // Запускаем пуллинг статуса
                setIsPolling(true);
            }
        } catch (error: any) {
            console.error('Ошибка при проверке карты:', error);
            setErrorMessage(error.message || 'Произошла ошибка при проверке карты');
            setIsSuccess(false);
            setIsLoading(false);
            setHasSubmitted(false);
            if (setIsFormValid) {
                setIsFormValid(false);
            }
        }
    };

    const handleReset = async () => {
        if (requestId) {
            try {
                // Сбрасываем статус операции в базе
                await resetOperationStatus({
                    id: requestId,
                    operationType: 'check-card'
                }).unwrap();

                // Сбрасываем только статусы, но не значение инпута
                setIsPolling(true); // Сразу включаем режим поллинга
                setPollingCount(0);
                setErrorMessage(null);
                setStatusMessage(null);
                setIsSuccess(false);
                setIsLoading(true);
                setHasSubmitted(true);
                if (setIsFormValid) {
                    setIsFormValid(false);
                }
            } catch (error) {
                console.error('Ошибка при сбросе статуса:', error);
            }
        }
    };

    // const handleResubmit = async () => {
    //     try {
    //         setIsLoading(true);
    //         setErrorMessage(null);
    //         setStatusMessage('Подготовка к отправке...');
    //         setIsSuccess(false);
    //         setPollingCount(0);

    //         // Сброс статуса операции на бэкенде
    //         await resetOperationStatus({ id: requestId, type: 'check_card' });

    //         // Повторная отправка запроса
    //         await checkCreditCard({ id: requestId });

    //         setHasSubmitted(true);
    //         setIsPolling(true);
    //     } catch (error) {
    //         setErrorMessage('Ошибка при повторной отправке запроса');
    //         setIsLoading(false);
    //         setIsPolling(false);
    //     }
    // };

    return (
        <div className={styles.wrapper}>
            <EanCardCheckForm
                onSubmit={handleFormSubmit}
                onReset={handleReset}
                isLoading={isLoading}
                isPolling={isPolling}
                hasSubmitted={hasSubmitted}
                errorMessage={errorMessage}
                statusMessage={statusMessage}
                isSuccess={isSuccess}
                isMobile={isMobile}
                showResetButton={!isSuccess && hasSubmitted && !isPolling}
            />
        </div>
    );
};

export default Step7;