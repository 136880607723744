import React from 'react';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import useHover from 'shared/hooks/useHover';

import styles from './MenuItem.module.scss';

interface MenuItemProps {
    icon: React.ReactNode; // Иконка, передаваемая как пропс
    text: string; // Текст пункта меню
    onClick: () => void; // Обработчик клика
    isActive?: boolean;
    hoveredColor?: string; // Цвет на ховер, если нужно
    defaultColor?: string; // Цвет по умолчанию
}

const MenuItem: React.FC<MenuItemProps> = ({ 
    icon, 
    text, 
    onClick, 
    isActive = false, 
    hoveredColor = '#71BE81', 
    defaultColor = '#AAAABB' 
}) => {
    const { isHovered, onMouseEnter, onMouseLeave } = useHover();

    // Клонируем иконку с нужными пропсами
    const iconWithProps = React.cloneElement(icon as React.ReactElement, { 
        active: isActive,
        hoveredColor,
        defaultColor,
        isHovered
    });

    return (
        <div
            className={styles.settingsItem}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <div className={styles.iconWrapper}>
                {iconWithProps}
            </div>
            <TextUI variant="menuMain" color={isActive || isHovered ? 'accent' : 'secondary'} align="left">
                {text}
            </TextUI>
        </div>
    );
};

export default MenuItem;