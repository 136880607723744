import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  useUploadPepConsentPhotoMutation,
  useUploadPepConsentPhotoSandboxMutation,
  useDeleteClientPhotoMutation,
  useGetCardRequestByIdQuery,
} from "services/api";
import { useDropzone } from "react-dropzone";
import ButtonUI from "components/ui/ButtonUI/ButtonUI";
import { TextUI } from "components/ui/TextUI/ui/TextUI";
import { CardWrapper } from "components/CardWrapper/CardWrapper";
// import { useMediaQuery } from "shared/hooks/UseMediaQuery";
import { Icon } from "@iconify/react";
import { PulseLoader } from "react-spinners";
import { Tooltip } from "components/ui/Tooltip";
import InfoIcon from "assets/icons/info.svg";
import FileIcon from "assets/icons/file_icon.svg";

import styles from "./PepConsentPhotoForm.module.scss";

interface PepConsentPhotoFormProps {
  requestId: string;
  setIsFormValid: (valid: boolean) => void;
  serverData?: {
    offer?: any;
    _id: string;
    userId: string;
    currentStep: number;
    status: string;
    pepConsentPhoto?: string;
    sandbox?: boolean;
  };
  onFormSubmit?: () => void;
  isGeneratingPep?: boolean;
  disabled?: boolean;
  onUploadSuccess?: () => void;
  statusMessage?: string;
  messageType?: "success" | "error";
  statusCode?: number;
}

// const formatFileSize = (size: number) => {
//   if (size < 1024) return `${size} B`;
//   if (size < 1048576) return `${(size / 1024).toFixed(1)} KB`;
//   return `${(size / 1048576).toFixed(1)} MB`;
// };

const validateFileType = (file: File) => {
  const validTypes = ["image/jpeg", "image/png", "application/pdf"];
  return validTypes.includes(file.type);
};

const getFileName = (file: string | File) => {
  if (typeof file === "string") {
    return file.split("/").pop() || file;
  }
  return file.name;
};

const PepConsentPhotoForm: React.FC<PepConsentPhotoFormProps> = ({
  requestId,
  setIsFormValid,
  serverData,
  onFormSubmit,
  isGeneratingPep,
  disabled = false,
  onUploadSuccess,
  statusMessage,
  messageType,
  statusCode,
}) => {
  console.log("🚀 ~ file: PepConsentPhotoForm.tsx:64 ~ serverData.sandbox:", serverData?.sandbox)
  const { data: cardRequestData, refetch } =
    useGetCardRequestByIdQuery(requestId);
  const [uploadPepConsentPhoto] = useUploadPepConsentPhotoMutation();
  const [uploadPepConsentPhotoSandbox] = useUploadPepConsentPhotoSandboxMutation();

  const [deletePepConsent] = useDeleteClientPhotoMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  // console.log("🚀 ~ file: PepConsentPhotoForm.tsx:70 ~ files:", files);
  const [fileUrls, setFileUrls] = useState<string[]>([]);
  const [isUploadSuccess, setUploadSuccess] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  //   const isMobile = useMediaQuery(768);

  // Делаем refetch при монтировании компонента
  useEffect(() => {
    if (requestId) {
      refetch();
    }
  }, [requestId, refetch]);

  useEffect(() => {
    const photo = cardRequestData?.pepConsentPhoto;

    if (photo && photo.trim() !== "") {
      const normalizedPath = photo.replace(/\\/g, "/");
      setFileUrls([normalizedPath]);
      setUploadSuccess(true);
      setIsFormValid(true);
    } else {
      setFileUrls([]);
      setUploadSuccess(false);
      // Не сбрасываем setIsFormValid здесь, так как фото могут быть в процессе загрузки
    }
  }, [cardRequestData, setIsFormValid]);

  useEffect(() => {
    // Форма валидна если:
    // 1. Есть загруженные файлы на сервере (fileUrls)
    // 2. Или есть успешно загруженные файлы (isUploadSuccess)
    const isValid = fileUrls.length > 0 || isUploadSuccess;
    // console.log("🔍 Form validity:", { fileUrls, isUploadSuccess, isValid });
    setIsFormValid(isValid);
  }, [fileUrls, isUploadSuccess, setIsFormValid]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const validFiles = acceptedFiles.filter((file) => {
        const isValid = validateFileType(file);
        if (!isValid) {
          setError("Ошибка. Поддерживаются только файлы JPG, PNG и PDF.");
        }
        // Проверяем, не выбран ли уже этот файл
        const isDuplicate =
          files.some((existingFile) => existingFile.name === file.name) ||
          fileUrls.some((url) => url.includes(file.name));
        if (isDuplicate) {
          setError("Этот файл уже выбран");
          return false;
        }
        return isValid;
      });

      if (validFiles.length > 0) {
        setFiles((prevFiles) => [...prevFiles, ...validFiles]);
        setError(null);
        setSuccessMessage(null);
        setUploadSuccess(false); // Сбрасываем статус успеха при добавлении новых файлов
      }
    },
    [files, fileUrls]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: disabled || isLoading || isGeneratingPep,
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
  });

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const normalizePath = (path: string) => path.replace(/\\/g, "/");

  const handleDeleteFile = async (index: number) => {
    if (index < fileUrls.length) {
      const fileUrl = fileUrls[index];
      try {
        await deletePepConsent({
          id: requestId,
          photoPath: normalizePath(fileUrl),
        }).unwrap();
        setFileUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
        setIsFormValid(false);
        // Обновляем данные после удаления
        await refetch();
      } catch (error) {
        console.error("Ошибка при удалении фото:", error);
        setError("Ошибка при удалении фото. Пожалуйста, попробуйте снова.");
      }
    } else {
      setFiles((prevFiles) =>
        prevFiles.filter((_, i) => i !== index - fileUrls.length)
      );
    }
  };

  // const handleSubmit = async () => {
  //     if (!onFormSubmit) return;

  //     try {
  //         setError(null);
  //         setSuccessMessage(null);

  //         if (files.length > 0) {
  //             const file = files[0]; // We only handle one file
  //             const formData = new FormData();
  //             formData.append('photos', file);

  //             const response = await uploadPepConsentPhoto({
  //                 requestId,
  //                 objectName: 'PDN',
  //                 formData
  //             }).unwrap();

  //             if (response) {
  //                 setUploadSuccess(true);
  //                 setFileUrls(prev => [...prev, response.path]);
  //                 setFiles([]);
  //                 setIsFormValid(true);
  //                 setSuccessMessage('Фото успешно загружены');
  //                 if (onUploadSuccess) {
  //                     onUploadSuccess();
  //                 }
  //                 await refetch();
  //             }
  //         }

  //         onFormSubmit();
  //     } catch (err) {
  //         console.error('Ошибка при загрузке фото:', err);
  //         setError('Ошибка при загрузке фото. Пожалуйста, попробуйте снова.');
  //         setIsFormValid(false);
  //     }
  // };

  const handleUpload = async () => {
    if (files.length === 0) {
      setError("Пожалуйста, выберите файл");
      return;
    }

    setIsLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      for (const file of files) {
        const formData = new FormData();
        formData.append("files", file);

        const uploadMethod = serverData?.sandbox
          ? uploadPepConsentPhotoSandbox
          : uploadPepConsentPhoto;

        const response = await uploadMethod({
          requestId,
          ...(serverData?.sandbox ? { formData } : { objectName: "PDN", formData }),
        }).unwrap();

        // console.log("Upload response:", response);
        setUploadSuccess(true);
        setSuccessMessage("Файл успешно загружен");
        if (onUploadSuccess) {
          onUploadSuccess();
        }
        refetch();
      }
    } catch (error: any) {
      console.error("Upload error:", error);
      setError(error.data?.message || "Ошибка при загрузке файла");
      setUploadSuccess(false);
    } finally {
      setIsLoading(false);
      setFiles([]); // Очищаем список файлов после загрузки
    }
  };

  return (
    <CardWrapper minWidth="512">
      <div className={styles.content}>
        <div className={styles.titleWrapper}>
          <TextUI variant="h2" className={styles.title}>
            Фото согласия на ПЭП
          </TextUI>
          <Tooltip
            maxWidth="310px"
            content={
              <div className={styles.tooltipWrapper}>
                Подписание согласия на бумаге с живой подписью Клиента и
                заверение живой подписью Агента
              </div>
            }
          >
            <img src={InfoIcon} alt="info" className={styles.infoIcon} />
          </Tooltip>
        </div>
        {statusMessage && !isUploadSuccess && !(fileUrls.length > 0) && (
          <div
            className={`${styles.statusMessage} ${styles[messageType || "error"]
              }`}
          >
            {statusCode === -1 && <div className={styles.loader} />}
            {statusMessage}
          </div>
        )}

        <>
          {isUploadSuccess || fileUrls.length > 0 ? (
            <div>
              <div className={styles.successBlock}>
                <Icon icon="mdi:check-circle" color="#4CAF50" width="24px" />
                <span>Фото успешно загружены</span>
              </div>
            </div>
          ) : (
            <div className={styles.uploadSection}>
              <div className={styles.header}>
                <div>Фото/сканы</div>
              </div>

              <div
                {...getRootProps()}
                className={`${styles.dropzone} ${disabled ? styles.disabled : ""
                  }`}
              >
                <input {...getInputProps()} ref={fileInputRef} />
                <div className={styles.uploadContent}>
                  <ButtonUI
                    label={"ЗАГРУЗИТЬ ФАЙЛЫ"}
                    variant="main2"
                    onClick={handleUploadClick}
                    disabled={disabled}
                    width="228px"
                    height="42px"
                  />
                  <p className={styles.addImageText}>
                    {disabled
                      ? "Дождитесь успешной генерации PEP"
                      : isDragActive
                        ? "Перетащите файл сюда..."
                        : "Или перетащите изображения сюда"}
                  </p>
                </div>
              </div>

              {/* Список загруженных файлов */}
              {files.length > 0 && (
                <div className={styles.fileList}>
                  {files.map((file, index) => (
                    <div key={index} className={styles.fileItem}>
                      <div className={styles.fileInfo}>
                        <img
                          src={FileIcon}
                          alt={getFileName(file)}
                          className={styles.previewImage}
                        />
                        <span className={styles.fileName}>{file.name}</span>
                        {/* <span className={styles.fileSize}>
                          ({formatFileSize(file.size)})
                        </span> */}
                      </div>

                      <ButtonUI
                        label="Удалить"
                        variant="link"
                        height="28px"
                        onClick={() => handleDeleteFile(index)}
                        disabled={disabled || isLoading}
                      />
                    </div>
                  ))}
                </div>
              )}

              {/* Кнопка отправки */}
              {files.length > 0 && (
                <ButtonUI
                  onClick={handleUpload}
                  disabled={isLoading || files.length === 0}
                  className={styles.uploadButton}
                  label={isLoading ? "" : "Отправить"}
                  variant="main"
                  width="100%"
                >
                  {isLoading && <PulseLoader color="#4caf50" size={8} />}
                </ButtonUI>
              )}
            </div>
          )}
        </>

        {/* Сообщения об ошибках и статусе */}
        {error && <div className={styles.error}>{error}</div>}
      </div>
    </CardWrapper>
  );
};

export default PepConsentPhotoForm;
