import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiSlice } from 'services/api';
import { UserData } from 'shared/models';

interface AuthState {
    user: UserData | null;
    token: string | null;
    isAuth: boolean;
    partnerId: string | null;
    isLoading: boolean;
    error: any | null;
}

const initialState: AuthState = {
    user: null,
    token: localStorage.getItem('token'),
    isAuth: !!localStorage.getItem('token'),
    partnerId: null,
    isLoading: false,
    error: null,
};

export const registerUser = createAsyncThunk(
    'auth/register',
    async (userData: UserData, { dispatch }) => {
      try {
        console.log('userData', userData);
        const response = await dispatch(apiSlice.endpoints.registerUser.initiate(userData)).unwrap();
        return response;
      } catch (error: any) {
        throw error;
      }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action: PayloadAction<{ user: UserData; accessToken: string }>) => {
            const { user, accessToken } = action.payload;
            state.user = user;
            state.token = accessToken;
            state.isAuth = true;
        },
        logout: (state) => {
            state.user = null;
            state.token = null;
            state.isAuth = false;
            state.partnerId = null;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(registerUser.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(registerUser.fulfilled, (state, action: PayloadAction<UserData>) => {
                state.isLoading = false;
                state.user = action.payload;
                state.error = null;
            })
            .addCase(registerUser.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { setCredentials, logout } = authSlice.actions;
export default authSlice.reducer;
