import React from 'react';

interface StatisticsIconProps {
    active?: boolean;
}

const StatisticsIcon: React.FC<StatisticsIconProps> = ({ active }) => {
    const color = active ? '#71BE81' : '#AAAABB';

    return (
        // <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <path d="M8 13V17M16 11V17M12 7V17M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        // </svg>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="16" fill={color} />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.9995 8.05591C10.2631 8.05591 8.85547 9.46353 8.85547 11.1999V20.7999C8.85547 22.5363 10.2631 23.9439 11.9995 23.9439H20.6995C22.4359 23.9439 23.8435 22.5363 23.8435 20.7999V11.1999C23.8435 9.46353 22.4359 8.05591 20.6995 8.05591H11.9995ZM10.3435 11.1999C10.3435 10.2853 11.0849 9.54391 11.9995 9.54391H20.6995C21.6141 9.54391 22.3555 10.2853 22.3555 11.1999V20.7999C22.3555 21.7145 21.6141 22.4559 20.6995 22.4559H11.9995C11.0849 22.4559 10.3435 21.7145 10.3435 20.7999V19.2317L12.2104 18.1648C12.4021 18.0553 12.6286 18.0241 12.8427 18.0776L14.5883 18.514C15.3486 18.7041 16.1536 18.5016 16.7335 17.9744L19.2555 15.6818V16.7999H20.7435V13.2559H16.7995V14.7439H18.075L15.7326 16.8734C15.5208 17.0659 15.2268 17.1399 14.9492 17.0704L13.2036 16.634C12.6172 16.4875 11.9969 16.573 11.4722 16.8729L10.3435 17.5179V11.1999Z" fill="white" />
        </svg>
    );
};

export default StatisticsIcon;
