import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import style from './InputUI.module.scss';
import clsx from 'clsx';
import { InputTypeEnum } from '../../../shared/models';
import EmailIcon from '../../../assets/icons/EmailIcon';
import PasswordIcon from '../../../assets/icons/PasswordIcon';
import PhoneIcon from '../../../assets/icons/PhoneIcon';
import MessengerIcon from '../../../assets/icons/MessengerIcon';
import TextIcon from '../../../assets/icons/TextIcon';
import EyeOpenIcon from '../../../assets/icons/EyeOpenIcon';
import EyeCloseIcon from '../../../assets/icons/EyeCloseIcon';

type InputProps = {
  field: React.InputHTMLAttributes<HTMLInputElement>;
  id: string;
  type: InputTypeEnum;
  error?: string;
  name: string;
  placeholder?: string;
  iconright?: ReactNode;
  hideIcon?: boolean; // Новый проп для отключения иконки
  onBlur: () => void;
};

export const InputUI = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const { error, placeholder, name, type, hideIcon = false } = props;
    const [passVisibleState, setPassVisibleState] = useState('password');

    const inputType = useMemo((): string => {
      if (type === InputTypeEnum.Email) return 'email';
      if (type === InputTypeEnum.Password) return passVisibleState;
      if (type === InputTypeEnum.Number) return 'number';
      if (type === InputTypeEnum.Date) return 'date';
      return type;
    }, [type, passVisibleState]);

    const changeVisiblePassFunc = useCallback(() => {
      setPassVisibleState((prevState) =>
        prevState === 'password' ? 'text' : 'password'
      );
    }, []);

    const handleFocus = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        if (type === InputTypeEnum.Tel && e.target.value === '') {
          e.target.value = '+7 9';
        }
      },
      [type]
    );

    useEffect(() => {
      if (error && props.field.value) {
        setPassVisibleState(''); // Или другой метод для сброса состояния ошибки
      }
    }, [props.field.value, error]);

    const renderLeftIcon = () => {
      if (hideIcon) return null; // Если hideIcon=true, иконка не отображается
      switch (type) {
        case InputTypeEnum.Email:
          return <EmailIcon />;
        case InputTypeEnum.Password:
          return <PasswordIcon />;
        case InputTypeEnum.Tel:
          return <PhoneIcon />;
        case InputTypeEnum.Messenger:
          return <MessengerIcon />;
        case InputTypeEnum.FIO:
          return <TextIcon />;
        case InputTypeEnum.Text:
        default:
          return <TextIcon />;
      }
    };

    const defaultPlaceholder = useMemo(() => {
      switch (type) {
        case InputTypeEnum.Email:
          return 'ВВЕДИТЕ EMAIL';
        case InputTypeEnum.Password:
          return 'ВВЕДИТЕ ПАРОЛЬ';
        case InputTypeEnum.Tel:
          return 'ВВЕДИТЕ ТЕЛЕФОН';
        case InputTypeEnum.Messenger:
          return 'ВВЕДИТЕ МЕССЕНДЖЕР';
        case InputTypeEnum.Date:
          return 'ВЫБЕРИТЕ ДАТУ';
        default:
          return placeholder;
      }
    }, [type, placeholder]);

    return (
      <div className={style.wrapper}>
        <div className={style.content}>
          {!hideIcon && <div className={style.iconleft}>{renderLeftIcon()}</div>}
          <input
            placeholder=' '
            className={clsx(style.input, error && style.error)}
            ref={ref}
            name={name}
            {...props.field}
            type={inputType}
            onFocus={handleFocus}
            onBlur={props.field.onBlur}
            autoComplete='on'
            onChange={(e) => {
              if (type === InputTypeEnum.Tel) {
                const value = e.target.value;
                if (value.length > 12) {
                  e.target.value = value.slice(0, 12);
                }
              }
              if (error) {
                // Здесь вы можете сбросить ошибку или обновить состояние
              }
              props.field.onChange?.(e);
            }}
          />
          <label
            className={clsx(style.floatingLabel, {
              [style.filled]: props.field.value || props.field.value === 0,
              [style.errorPlaceholder]: error,
              [style.hideIcon]: hideIcon,
            })}
          >
            {placeholder || defaultPlaceholder}
          </label>
          {type === InputTypeEnum.Password && (
            <button
              type='button'
              onClick={(e) => {
                e.preventDefault();
                changeVisiblePassFunc();
              }}
              className={style.passEyeButton}
            >
              {passVisibleState === 'password' ? (
                <EyeOpenIcon />
              ) : (
                <EyeCloseIcon />
              )}
            </button>
          )}
        </div>
        {error && <span className={style.errorMessage}>{error}</span>}
      </div>
    );
  }
);

