import React, { useEffect, useState, useCallback, useRef } from 'react';
import Select, { ActionMeta, components, MultiValue, SingleValue } from 'react-select';
import LoadingOverlay from 'components/ui/LoadingOverlay/LoadingOverlay';
import SearchIcon from 'assets/icons/SearchIcon';
import { OptionType, SingleSelectSearchProps } from 'components/Offers/model/types';
import { useMediaQuery } from 'shared/hooks/UseMediaQuery';
import ArrowDown from 'assets/icons/arrow_down.svg'

import style from './singleSelectSearch.module.scss';

export const SingleSelectSearch: React.FC<SingleSelectSearchProps & { simpleSelect?: boolean }> = ({
    options,
    onOptionSelect,
    isLoading = false,
    placeholder = 'Выбрать оффер',
    defaultValue,
    reset,
    title = 'Поиск',
    simpleSelect = false // Новый проп для включения режима с иконкой стрелки
}) => {
    const [inputText, setInputText] = useState<string>('');
    const [filteredOptions, setFilteredOptions] = useState<OptionType[]>([]);
    const [selectedOption, setSelectedOption] = useState<OptionType | null>(defaultValue || null);

    const allOption: OptionType = { label: 'Все', value: '' };
    const selectRef = useRef<HTMLDivElement | null>(null);

    const isMobile = useMediaQuery(768);

    // Фильтрация по введённому тексту
    useEffect(() => {
        const filtered = options.filter(option => {
            const matchesLabel = option.label?.toLowerCase().includes(inputText.toLowerCase());
            const matchesId = option.value?.toString().includes(inputText);
            return matchesLabel || matchesId;
        });
        setFilteredOptions([allOption, ...filtered]);
    }, [inputText, options]);

    // Сбрасываем состояние при изменении пропса `reset`
    useEffect(() => {
        if (reset) {
            setSelectedOption(null); // сбрасываем выбранный оффер
            setInputText(''); // очищаем текст поиска
        }
    }, [reset]);

    const handleInputChange = (inputText: string) => {
        setInputText(inputText);
    };

    const handleOptionSelect = (
        newValue: SingleValue<OptionType> | MultiValue<OptionType>,
        actionMeta: ActionMeta<OptionType>
    ) => {

        console.log('newValue', newValue);
        if (newValue && !Array.isArray(newValue)) {
            if ('value' in newValue && newValue?.value === '') {
                setSelectedOption(null);
                onOptionSelect(null);
            } else {
                setSelectedOption(newValue as OptionType | null);
                onOptionSelect(newValue as OptionType | null);
            }
        } else {
            setSelectedOption(null);
        }
    };

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            borderColor: '#D9D9D9',
            height: '42px',
            borderRadius: '10px',
            boxShadow: state.isFocused ? '0 0 0 1px #71BE81' : 'none',
            '&:hover': { borderColor: '#71BE81' },
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#EBEBEB' : 'transparent',
            color: state.isSelected ? '#000' : '#6C717B',
            '&:hover': { backgroundColor: '#EBEBEB' },
        }),
    };

    // const DropdownIndicator = (props: any) => (
    //     <components.DropdownIndicator {...props}>
    //         {simpleSelect ? <span>^</span> : <SearchIcon />} {/* &#9662; Показываем стрелку или иконку поиска */}
    //     </components.DropdownIndicator>
    // );

    // const DropdownIndicator = (props: any) => (
    //     <components.DropdownIndicator {...props}>
    //         {/* Показываем стрелку или SearchIcon и добавляем поворот стрелки */}
    //         {simpleSelect ? (
    //             <span style={{ transform: props.selectProps.menuIsOpen ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 0.2s ease' }}>
    //                 ^
    //             </span>
    //         ) : (
    //             <SearchIcon />
    //         )}
    //     </components.DropdownIndicator>
    // );
    const DropdownIndicator = (props: any) => (
        <components.DropdownIndicator {...props}>
            {/* Используем иконку ArrowDown с поворотом */}
            {simpleSelect ? (
                <img
                    src={ArrowDown}
                    alt="Arrow Down"
                    style={{
                        transform: props.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.2s ease'
                    }}
                />
            ) : (
                <SearchIcon />
            )}
        </components.DropdownIndicator>
    );


    const NoOptionsMessage = (props: any) => (
        <components.NoOptionsMessage {...props}>
            <span>Данные не найдены</span>
        </components.NoOptionsMessage>
    );

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
                setInputText('');
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={style.selectWrapper} ref={selectRef}>
            {simpleSelect ? <div>{title}</div> : !isMobile && <div>{title}</div>}
            <Select
                value={selectedOption}
                onChange={handleOptionSelect}
                onInputChange={handleInputChange}
                inputValue={inputText}
                options={filteredOptions}
                isClearable
                isSearchable
                placeholder={placeholder}
                components={{
                    DropdownIndicator,
                    NoOptionsMessage,
                    IndicatorSeparator: () => null,
                    ClearIndicator: () => null,
                    MenuList: isLoading ? () => <LoadingOverlay /> : components.MenuList,
                }}
                styles={customStyles}
            />
        </div>
    );
};
