import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { CustomInput } from '../../../CustomInput/CustomInput';
import { InputTypeEnum } from '../../../../shared/models';
import { useLoginUserMutation } from 'services/api';
import styles from './LoginForm.module.scss';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import { Link, useNavigate } from 'react-router-dom';
import LoadingOverlay from 'components/ui/LoadingOverlay/LoadingOverlay'; // Импортируйте ваш компонент модального окна
import { Modal } from 'components/ui/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from 'features/login/loginSlice';
import { RootState } from 'app/store';

interface LoginData {
  email: string;
  password: string;
}

const LoginForm = () => {
  const methods = useForm<LoginData>();
  const [loginUser, { isLoading }] = useLoginUserMutation(); // Используем хук RTK Query
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleSubmit, formState } = methods;
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Состояние для открытия модального окна

  const onSubmit = async (data: LoginData) => {
    const isValid = await methods.trigger();

    if (isValid) {
      try {
        const result = await loginUser(data).unwrap();
        // console.log("🚀 ~ onSubmit ~ result:", result);

        localStorage.setItem('token', result.accessToken);

        // Проверяем статус пользователя
        if (result.user.status === 'active' && result.user.authorized) {
          dispatch(setUser(result.user));
          // console.log("🚀 ~ User status:", result.user.status);

          // Если пользователь активен, перенаправляем на /settings
          navigate('/settings');
          return;
        }

        if (!result.user.authorized) {
          // Если пользователь не авторизован
          setIsModalOpen(true);
          return;
        }

        if (result.user.status === 'inactive') {
          // Перенаправляем на страницу активации
          navigate('/authorize-with-contact-agent');
          return;
        }

        setIsModalOpen(true); // Открываем модальное окно для неизвестного статуса

      } catch (error) {
        const errorMessage = (error as any).response?.data?.message || '';

        if (errorMessage.includes('email')) {
          setEmailError(errorMessage);
        } else if (errorMessage.includes('password')) {
          setPasswordError(errorMessage);
        }

        setIsModalOpen(true); // Открыть модальное окно при любой ошибке
      }
    } else {
      setIsModalOpen(true); // Открыть модальное окно если форма невалидна
    }
  };

  const closeModal = () => setIsModalOpen(false); // Функция для закрытия модального окна

  return (
    <FormProvider {...methods}>
      {isLoading && <LoadingOverlay />}
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        message='Некоторые поля не заполнены или заполнены неверно'
      />
      <form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
        <TextUI variant="h1" align='center'>Вход</TextUI>

        <CustomInput
          name='email'
          type={InputTypeEnum.Email}
          placeholder='ВВЕДИТЕ EMAIL'
          serverError={emailError}
        />
        <CustomInput
          name='password'
          type={InputTypeEnum.Password}
          placeholder='ВВЕДИТЕ ПАРОЛЬ'
          serverError={passwordError}
        />
        <div className={styles.toForgot}>
          <Link to='/recovery-password'>
            <ButtonUI label={'Забыли пароль?'} height='50px' variant={'linkDark'} />
          </Link>
        </div>
        <ButtonUI type='submit' label='Войти' variant='main' fullWidth={true} />
        <div className={styles.toRegister}>
          <TextUI variant='bodyS' color='secondary' align='center'>
            Нет аккаунта?
          </TextUI>
          <Link to='/registration'>
            <ButtonUI label={'Регистрация'} variant={'link'} fullWidth={true} />
          </Link>
        </div>
      </form>
    </FormProvider>
  );
};

export default LoginForm;
