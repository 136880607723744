import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ButtonUI.module.scss';

interface ButtonUIProps {
    label: string;
    variant: 'main' | 'main2' | 'mainGreen' | 'secondary' | 'secondary2' | 'link' | 'linkDark' | 'small' | 'small2';
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    className?: string;
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    to?: string; // Путь для навигации
    height?: string;
    width?: string;
    iconLeft?: React.ReactNode; // Иконка слева
    iconRight?: React.ReactNode; // Иконка справа
    fullWidth?: boolean;
    loading?: boolean;
    children?: React.ReactNode;
}

const ButtonUI: React.FC<ButtonUIProps> = ({
    label,
    variant,
    onClick,
    className,
    type = 'button',
    disabled = false,
    to,
    height = '64px',
    width,
    iconLeft,
    iconRight,
    fullWidth = false,
    children,
}) => {
    const navigate = useNavigate();
    const buttonClass = `${styles.button} ${styles[variant]} ${disabled ? styles.disabled : ''} ${fullWidth ? styles.fullWidth : ''} ${className || ''}`;

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (onClick) {
            onClick(event);
        }
        if (to && !disabled) {
            navigate(to);
        }
    };

    return (
        <button
            type={type}
            className={buttonClass}
            onClick={handleClick}
            disabled={disabled}
            style={{ height, width }} // Применение высоты через inline-стили
        >
            {iconLeft && <span className={styles.iconLeft}>{iconLeft}</span>}
            <div>{label}</div>
            {iconRight && <span className={styles.iconRight}>{iconRight}</span>}
            <div className={styles.children}>
                {children}
            </div>
        </button>
    );
};

export default ButtonUI;
