import React from 'react';

interface ApplicationsIconProps {
    active?: boolean;
}

const ApplicationsIcon: React.FC<ApplicationsIconProps> = ({ active }) => {
    const color = active ? '#71BE81' : '#AAAABB';

    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.7634 13.2951H8.82033V20.221C8.82033 21.1511 9.57428 21.905 10.5043 21.905H15.7463C16.6764 21.905 17.4303 21.1511 17.4303 20.221V19.1923H15.4187C15.0821 19.1923 14.8093 18.9195 14.8093 18.5829C14.8093 18.2464 15.0821 17.9735 15.4187 17.9735H17.4303V11.7028C17.4303 10.7728 16.6764 10.0188 15.7463 10.0188H10.5043C9.57428 10.0188 8.82033 10.7728 8.82033 11.7028V12.0763H14.7634C15.1 12.0763 15.3728 12.3491 15.3728 12.6857C15.3728 13.0222 15.1 13.2951 14.7634 13.2951Z" fill={color} />
            <path fillRule="evenodd" clipRule="evenodd" d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM10.5043 8.80005C8.90117 8.80005 7.60156 10.0997 7.60156 11.7028V20.221C7.60156 21.8242 8.90117 23.1238 10.5043 23.1238H15.7463C17.3495 23.1238 18.6491 21.8242 18.6491 20.221V19.1923H23.121L20.2832 22.0301C20.0452 22.2681 20.0452 22.6539 20.2832 22.8919C20.5212 23.1299 20.907 23.1299 21.145 22.8919L25.0231 19.0138C25.2611 18.7758 25.2611 18.39 25.0231 18.152L21.145 14.2739C20.907 14.036 20.5212 14.036 20.2832 14.2739C20.0452 14.5119 20.0452 14.8978 20.2832 15.1357L23.121 17.9735H18.6491V11.7028C18.6491 10.0997 17.3495 8.80005 15.7463 8.80005H10.5043Z" fill={color} />
        </svg>
    );
};

export default ApplicationsIcon;
