export const formatDate = (date: Date | null) => {
    if (!date || isNaN(new Date(date).getTime())) {
        return ''; // Если дата некорректная или отсутствует, возвращаем пустую строку
    }

    const validDate = new Date(date); // Преобразуем в объект Date

    return validDate.toLocaleDateString('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
};