import React, { useEffect, useState, useRef } from 'react';
import {
    useIssueCardMutation,
    useGetBidStatusQuery
} from 'services/api';
import { skipToken } from '@reduxjs/toolkit/query/react';
// import Loader from 'components/Loader/Loader';
import ClockIcon from 'assets/icons/clock.svg';
import CheckedIcon from 'assets/icons/checked.svg';
import styles from './Step10.module.scss';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';

const POLLING_INTERVAL = 3000; // 3 секунды
const MAX_POLLING_ATTEMPTS = 20; // Максимальное количество попыток

interface Step10Props {
    requestId: string | null;
    onStatusChange?: (status: number | undefined) => void;
    setIsFormValid: (isValid: boolean) => void;
}

const Step10: React.FC<Step10Props> = ({ requestId, onStatusChange, setIsFormValid }) => {
    const [issueCard] = useIssueCardMutation();
    const [isPolling, setIsPolling] = useState(false);
    const [pollingCount, setPollingCount] = useState(0);
    const [statusMessage, setStatusMessage] = useState<string>('Карта скоро будет готова для выдачи клиенту');
    const [messageType, setMessageType] = useState<'success' | 'error'>('error');
    const [showConfirmButton, setShowConfirmButton] = useState(false);
    const prevPartnerResponseCode = useRef<number | null>(null);

    const { data: statusData } = useGetBidStatusQuery(
        requestId ? { id: requestId, type: 'issue' } : skipToken,
        {
            pollingInterval: isPolling ? POLLING_INTERVAL : 0,
            refetchOnMountOrArgChange: true
        }
    );

    const currentStatus = statusData?.message?.partnerResponseCode;

    useEffect(() => {
        const checkAndIssueCard = async () => {
            if (!requestId) return;

            if (currentStatus === -1) {
                try {
                    setIsPolling(true);
                    await issueCard(requestId).unwrap();
                    setStatusMessage('Запрос на выдачу карты отправлен');
                    setMessageType('success');
                } catch (error) {
                    setStatusMessage('Ошибка при отправке запроса на выдачу карты');
                    setIsPolling(false);
                }
            }
        };

        checkAndIssueCard();
    }, [requestId, issueCard, currentStatus]);

    useEffect(() => {
        if (!statusData) return;

        const { partnerResponseCode } = statusData.message || {};

        if (prevPartnerResponseCode.current !== partnerResponseCode && partnerResponseCode !== -1) {
            setPollingCount(prev => prev + 1);
            prevPartnerResponseCode.current = partnerResponseCode;
        }

        if (partnerResponseCode !== undefined) {
            onStatusChange?.(partnerResponseCode);

            switch (partnerResponseCode) {
                case -1:
                    setStatusMessage('В ожидании подписания документов от Банка');
                    setMessageType('error');
                    break;
                case 0:
                    setIsPolling(false);
                    setStatusMessage('Карта готова для выдачи клиенту');
                    setMessageType('success');
                    setIsFormValid(true);
                    setShowConfirmButton(true);
                    break;
                default:
                    setIsPolling(false);
                    setStatusMessage('Произошла ошибка при выдаче карты');
                    setMessageType('error');
                    break;
            }
        }

        if (pollingCount >= MAX_POLLING_ATTEMPTS) {
            setIsPolling(false);
            setStatusMessage('Превышено максимальное количество попыток');
            setMessageType('error');
        }
    }, [statusData, onStatusChange, pollingCount, setIsFormValid]);

    return (
        <div className={styles.cardWrapper}>
            <div className={`${styles.card} ${messageType === 'success' ? styles.successBorder : ''}`}>
                <h3 className={styles.title}>{statusMessage}</h3>
                <div>Статус:</div>
                {messageType === 'error' && (
                    <div className={styles.statusWrapper}>
                        {isPolling && (
                            <img
                                src={ClockIcon}
                                alt="Загрузка"
                                className={`${styles.statusIcon} ${styles.rotating}`}
                            />
                        )}
                        <span className={styles.statusText}>{statusMessage}</span>
                    </div>
                )}
                {messageType === 'success' && showConfirmButton && (
                    <div className={styles.confirmSection}>
                        <img
                            src={CheckedIcon}
                            alt="Загрузка"
                            className={`${styles.statusIcon} ${styles.rotating}`}
                        />
                        <p>Одобрено</p>
                    </div>
                )}
                {isPolling && (
                    <div className={styles.loaderWrapper}>
                        {/* <Loader /> */}
                        <img
                            src={ClockIcon}
                            alt="Загрузка"
                            className={`${styles.statusIcon} ${styles.rotating}`}
                        />
                        <span>{statusMessage}</span>
                    </div>
                )}
            </div>
            {messageType === 'success' && showConfirmButton && (
                <div className={styles.confirmButtonWrapper}>
                    <p className={styles.confirmText}>Документ с банком подписан</p>
                    <div className={styles.confirmDescription}>Подтвердите передачу карты клиенту</div>
                    <ButtonUI label="Подтвердить" variant="main" height='64px' width='275px' />
                    {/* <button className={styles.confirmButton}>Подтвердить</button> */}
                </div>
            )}
        </div>
    );
};

export default Step10;
