import React, { useState, useRef, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import CalendarIcon from 'assets/icons/CalendarIcon';
import useOnClickOutside from 'shared/hooks/useOnClickOutside';

import styles from './DualCalendarSelector.module.scss';

interface DualCalendarSelectorProps {
    onDateChange: (range: [Date, Date]) => void;
    dateRange: [Date, Date];
    resetDates?: boolean;
    title?: string;
    showDoubleView?: boolean;
}

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const getDefaultRange = (): Value => {
    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 150);  // Отнимаем 7 дней от текущей даты
    return [sevenDaysAgo, today];
};

const getPresetRange = (preset: string): [Date, Date] => {
    const today = new Date();
    today.setHours(23, 59, 59, 999); // Устанавливаем конец дня

    switch (preset) {
        case 'today': {
            const start = new Date();
            start.setHours(0, 0, 0, 0); // Начало дня
            return [start, today];
        }
        case 'yesterday': {
            const start = new Date();
            start.setDate(today.getDate() - 1);
            start.setHours(0, 0, 0, 0); // Начало вчерашнего дня

            const end = new Date();
            end.setDate(today.getDate() - 1);
            end.setHours(23, 59, 59, 999); // Конец вчерашнего дня

            return [start, end];
        }
        case 'thisWeek': {
            const start = new Date(today);
            start.setDate(today.getDate() - today.getDay() + 1);
            start.setHours(0, 0, 0, 0); // Начало недели
            return [start, today];
        }
        case 'lastWeek': {
            const start = new Date(today);
            const end = new Date(today);
            start.setDate(today.getDate() - today.getDay() - 6);
            end.setDate(today.getDate() - today.getDay());
            start.setHours(0, 0, 0, 0); // Начало прошлой недели
            end.setHours(23, 59, 59, 999); // Конец прошлой недели
            return [start, end];
        }
        case 'thisMonth': {
            const start = new Date(today.getFullYear(), today.getMonth(), 1);
            start.setHours(0, 0, 0, 0); // Начало месяца
            return [start, today];
        }
        default:
            return [today, today];
    }
};

const formatDate = (date: Date): string => {
    return date.toLocaleDateString('ru-RU');
};

const DualCalendarSelector: React.FC<DualCalendarSelectorProps> = ({
    onDateChange,
    dateRange,
    title = 'Дата заявки',
    resetDates = false,
    showDoubleView = true
}) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [activePreset, setActivePreset] = useState<string | null>(null);
    const [activeStartDate, setActiveStartDate] = useState<Date>(new Date());

    const calendarRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(calendarRef, () => setShowCalendar(false));

    useEffect(() => {
        if (resetDates) {
            const defaultRange = getDefaultRange() as [Date, Date];
            onDateChange(defaultRange);
            setActiveStartDate(defaultRange[0]);
            setActivePreset(null);
        }
    }, [resetDates, onDateChange]);

    const handleDateChange = (value: Value) => {
        if (Array.isArray(value)) {
            onDateChange(value as [Date, Date]);  // Передаем новое значение родителю
            setActiveStartDate(value[0] as Date); // Устанавливаем активный месяц на начало диапазона
            setActivePreset(null); // Сбрасываем активный пресет при ручном выборе
        } else if (value) {
            onDateChange([value, value]); // Обрабатываем случай одиночного значения
            setActiveStartDate(value);
            setActivePreset(null);
        }
    };

    const applyPreset = (preset: string) => {
        const range = getPresetRange(preset);
        handleDateChange(range);
        setActivePreset(preset);
        setActiveStartDate(range[0]); // Устанавливаем активный месяц на начало выбранного диапазона
    };

    const handleReset = () => {
        const defaultRange = getDefaultRange() as [Date, Date];
        handleDateChange(defaultRange);
        setActivePreset(null);
        setActiveStartDate(defaultRange[0]);
    };

    const handleConfirm = () => {
        setShowCalendar(false);
    };

    return (
        <div className={styles.calendarSelectContainer} ref={calendarRef}>
            <div className={styles.titleWrapper}>
                {title && <div className={styles.title}>{title}</div>}
                <div
                    className={styles.datePicker}
                    onClick={() => setShowCalendar(!showCalendar)}
                >
                    <span>
                        <span className={styles.highlightedStartDate}>{formatDate(dateRange[0])}</span> - {formatDate(dateRange[1])}
                    </span>
                    <CalendarIcon />
                </div>
            </div>

            {showCalendar && (
                <div className={styles.calendarDropdown}>
                    <div className={styles.presets}>
                        <button
                            className={activePreset === 'today' ? styles.activePreset : ''}
                            onClick={() => applyPreset('today')}
                        >
                            За сегодня
                        </button>
                        <button
                            className={activePreset === 'yesterday' ? styles.activePreset : ''}
                            onClick={() => applyPreset('yesterday')}
                        >
                            За вчера
                        </button>
                        <button
                            className={activePreset === 'thisWeek' ? styles.activePreset : ''}
                            onClick={() => applyPreset('thisWeek')}
                        >
                            За текущую неделю
                        </button>
                        <button
                            className={activePreset === 'lastWeek' ? styles.activePreset : ''}
                            onClick={() => applyPreset('lastWeek')}
                        >
                            За прошлую неделю
                        </button>
                        <button
                            className={activePreset === 'thisMonth' ? styles.activePreset : ''}
                            onClick={() => applyPreset('thisMonth')}
                        >
                            За месяц
                        </button>
                    </div>

                    <div className={styles.calendars}>
                        <Calendar
                            value={dateRange}
                            onChange={handleDateChange}
                            locale="ru-RU"
                            showDoubleView={showDoubleView}
                            selectRange={true}
                            activeStartDate={activeStartDate}
                            onActiveStartDateChange={({ activeStartDate }) =>
                                activeStartDate && setActiveStartDate(activeStartDate)
                            }
                        />
                    </div>

                    <div className={styles.calendarActions}>
                        <button
                            className={styles.resetButton}
                            onClick={handleReset}
                        >
                            СБРОСИТЬ
                        </button>
                        <button
                            className={styles.confirmButton}
                            onClick={handleConfirm}
                        >
                            ОК
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DualCalendarSelector;
