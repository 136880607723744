import { useRef, useState } from "react";
import {
    useFloating,
    offset,
    flip,
    shift,
    useHover,
    useInteractions,
    FloatingPortal,
    arrow
} from "@floating-ui/react";

import styles from './Tooltip.module.scss';

interface TooltipProps {
    content: React.ReactNode;
    children?: React.ReactNode;
    maxWidth?: string;
}

const Tooltip = ({ content, children, maxWidth = '450px' }: TooltipProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const arrowRef = useRef(null);

    const { refs, floatingStyles, context, middlewareData } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: 'top',
        middleware: [
            offset(10),
            flip(),
            shift(),
            arrow({
                element: arrowRef,
            })
        ],
    });

    const hover = useHover(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

    const arrowPosition = middlewareData.arrow; // Arrow positioning data

    return (
        <>
            <FloatingPortal>
                {isOpen && (
                    <div
                        ref={refs.setFloating}
                        style={{ ...floatingStyles, maxWidth: maxWidth }}
                        className={styles.tooltipContent}
                        {...getFloatingProps()}
                    >
                        {content}
                        <div
                            ref={arrowRef}
                            className={styles.tooltipArrow}
                            style={{
                                left: arrowPosition?.x != null ? `${arrowPosition.x}px` : '',
                                top: arrowPosition?.y != null ? `${arrowPosition.y}px` : '',
                            }}
                        />
                    </div>
                )}
            </FloatingPortal>
            <span
                ref={refs.setReference}
                className={styles.tooltipTrigger}
                {...getReferenceProps()}
            >
                {children}
            </span>
        </>
    );
};

export default Tooltip;
