import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'features/login/loginSlice';
import { useNavigate } from 'react-router-dom';

export const useStorageListener = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      // Проверяем изменение в persist:root, где хранится состояние redux
      if (e.key === 'persist:root') {
        const newValue = e.newValue ? JSON.parse(e.newValue) : null;
        const oldValue = e.oldValue ? JSON.parse(e.oldValue) : null;

        // Если в новом значении isAuth стал false, а в старом был true
        if (newValue?.login?.isAuth === false && oldValue?.login?.isAuth === true) {
          dispatch(logoutUser());
          navigate('/login');
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [dispatch, navigate]);
};
