import React from 'react'
import MainContainer from 'components/ui/MainContainer/MainContainer'
import MainHeader from 'components/ui/MainHeader/MainHeader'
import RouterComponent from 'components/ui/Router/Router'
import FooterComponent from 'components/ui/Footer/Footer'
import styles from './MainAppLayout.module.scss'
import { useSelector } from 'react-redux'
import { RootState } from 'app/store'

const MainAppLayout = ({ children }: any) => {
  const isAuth = useSelector((state: RootState) => state.login.isAuth);
  
  return (
    <div className={styles.layout}>
      <MainHeader />
      {isAuth && <RouterComponent />}
      <div className={styles.content}>
        <MainContainer>
          {children}
        </MainContainer>
      </div>
      <FooterComponent />
    </div>
  )
}

export default MainAppLayout