import React, { forwardRef, useEffect, useRef, useState } from 'react';
import profiArrow from '../../../assets/icons/profi-arrow.svg';
import LogoIcon from 'assets/icons/LogoIcon';
import ArrowGray from '../../../assets/icons/arrow-gray.svg';
import buttonHome from '../../../assets/icons/button-home.svg';
import BurgerMenuIcon from 'assets/icons/BurgerMenuIcon';
import CancelIcon from 'assets/icons/CancelIcon';
import HomeIcon from 'assets/icons/HomeIcon';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from 'features/login/loginSlice';
import { RootState } from 'app/store';
import MainContainer from '../MainContainer/MainContainer';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextUI } from '../TextUI/ui/TextUI';
import HeaderSettingsIcon from 'assets/icons/HeaderSettingsIcon';
import StatisticsIcon from 'assets/icons/StatisticsIcon';
import ApplicationsIcon from 'assets/icons/ApplicationsIcon';
import LogoutIcon from 'assets/icons/LogoutIcon';
import { apiSlice } from 'services/api';
import { MenuItem } from 'components/MenuItem';
import OffersIcon from 'assets/icons/OffersIcon';
import ButtonUI from '../ButtonUI/ButtonUI';
// import { Modal } from '../Modal';
import { ModalWithChildren } from '../ModalWithChildren';
import clsx from 'clsx';

import styles from './MainHeader.module.scss';

interface SettingsModalProps {
  handleLogout: () => void;
  className?: any
}

const SettingsModal = forwardRef<HTMLDivElement, SettingsModalProps>(({ handleLogout, className }, ref) => {
  const navigate = useNavigate();
  const location = useLocation();

  // const { isHovered, onMouseEnter, onMouseLeave } = useHover();

  const toSettings = () => {
    navigate('/settings');
  };

  return (
    <div ref={ref} className={`${styles.settingsModal} ${className}`}> {/* Реф передается здесь */}

      <MenuItem
        icon={<HeaderSettingsIcon />}
        text="Настройки"
        onClick={toSettings}
        hoveredColor="#71BE81"
        defaultColor="#AAAABB"
        isActive={location.pathname === '/settings'}
      />
      <MenuItem
        icon={<LogoutIcon />}
        text="ВЫЙТИ"
        onClick={handleLogout}
        hoveredColor="#71BE81"
        defaultColor="#AAAABB"
      />
    </div>
  );
});

const MainHeader = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  // Отдельные состояния для мобильной и десктопной версий
  const [showDesktopSettings, setShowDesktopSettings] = useState(false);
  const [showMobileSettings, setShowMobileSettings] = useState(false);
  // console.log("🚀 ~ MainHeader ~ showMobileSettings:", showMobileSettings)
  const [showMobileVersionSettings, setShowMobileVersionSettings] = useState(false);
  const [isHomeModalOpen, setIsHomeModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const mobileMenuRef = useRef<HTMLDivElement>(null);

  const user = useSelector((state: any) => state.login?.user);

  const menuRef = useRef<HTMLDivElement | null>(null);

  const handleHomeClick = () => {
    setIsHomeModalOpen(true);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowDesktopSettings(false); // Закрываем десктопное меню
        setShowMobileSettings(false);  // Закрываем мобильное меню
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement)?.closest(`.${styles.menuIcon}`) &&
        !(event.target as HTMLElement)?.closest(`.${styles.mobileMenu}`)
      ) {
        handleCloseMobileModal();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Обработчик для десктопа
  const handleOpenDesktopModal = () => {
    setShowDesktopSettings(!showDesktopSettings);
  };

  const handleOpenMobileModal = () => {
    setShowMobileVersionSettings(true);
    setIsMenuOpen(true);
  };

  const handleCloseMobileModal = () => {
    setShowMobileVersionSettings(false);
    setIsMenuOpen(false);
  };

  const handleMenuToggle = () => {
    if (showMobileVersionSettings) {
      handleCloseMobileModal();
    } else {
      handleOpenMobileModal();
    }
  };

  const navigate = useNavigate();

  const isAuth = useSelector((state: RootState) => state.login.isAuth);

  const handleLogout = () => {
    dispatch(logoutUser());
    dispatch(apiSlice.util.resetApiState());
    navigate('/login')
  };

  const handleMain = () => {
    navigate('/main')
  }

  const handleOffer = () => {
    navigate('/offers')
  }

  const handleDailyStatistic = () => {
    navigate('/daily-statistic')
  }

  const handleStatisticByOffers = () => {
    navigate('/statistic-by-offers')
  }


  const handleApplications = () => {
    navigate('/applications')
  }

  const handleSettings = () => {
    navigate('/settings')
  }
  // console.log("🚀 ~ file: MainHeader.tsx:180 ~ isAuth && location.pathname !== '/request-card':", isAuth && location.pathname.includes('/request-card'))
  // console.log("🚀 ~ file: MainHeader.tsx:180 ~ isAuth :", isAuth)

  return (
    <header className={styles.mainHeader}>
      <MainContainer>
        <div className={styles.fullWidth}>
          <LogoIcon />
          {/* Бургер-меню для мобильных устройств */}
          <div className={styles.mobileMenu} onClick={location.pathname.includes('/request-card') ? handleHomeClick : handleMenuToggle}>
            {(isAuth && location.pathname.includes('/request-card')) ? null : isMenuOpen ? <CancelIcon /> : <BurgerMenuIcon />}
            {isAuth && location.pathname.includes('/request-card') && <img src={buttonHome} alt="Home" />}
          </div>

          {showMobileVersionSettings && (
            <div ref={mobileMenuRef} className={`${styles.mobileVerisonSettingsModal}`}> {/* Реф передается здесь */}

              <div className={styles.headerRightSideLeft}>
                <p>{user?.email}</p>
                <p className={styles.idText}>id{user?.userId}</p>
              </div>

              <div className={styles.horizontalLine}></div>

              <div className={styles.settingsItem} onClick={handleMain} >
                <div className={styles.iconWrapper}>
                  <HomeIcon active={location.pathname === '/main'} />
                </div>
                <TextUI color="secondary" className={location?.pathname === "/main" ? styles.activeRoute : ''} variant="menuMain" align="left">главная</TextUI>
              </div>

              <div className={styles.settingsItem} onClick={handleOffer} >
                <div className={styles.iconWrapper}>
                  <OffersIcon active={location.pathname === '/offers'} />
                </div>
                <TextUI color="secondary" className={location?.pathname === "/offers" ? styles.activeRoute : ''} variant="menuMain" align="left">офферы</TextUI>
              </div>
              <div>
                <div className={`${styles.settingsItem} ${styles.statisticsItem}`}>
                  <div className={styles.iconWrapper}>
                    <StatisticsIcon active={location.pathname === '/daily-statistic' || location.pathname === '/statistic-by-offers'} />
                  </div>
                  <TextUI color="secondary" variant="menuMain" align="left">Статистика</TextUI>
                </div>
                <div className={styles.headerStatisticBox}>
                  <div className={styles.verticalLine}></div>
                  <ul>
                    <li
                      onClick={handleDailyStatistic}
                      className={location.pathname === '/daily-statistic' ? clsx(styles.menuMain, styles.activeRoute) : styles.menuMain}
                    >
                      ПО ДНЯМ
                    </li>
                    <li
                      onClick={handleStatisticByOffers}
                      className={location.pathname === '/statistic-by-offers' ? clsx(styles.menuMain, styles.activeRoute) : styles.menuMain}
                    >
                      ПО ОФФЕРАМ
                    </li>
                  </ul>
                </div>
              </div>

              <div className={styles.settingsItem} onClick={handleApplications} >
                <div className={styles.iconWrapper}>
                  <ApplicationsIcon active={location.pathname === '/applications'} />
                </div>
                <TextUI color="secondary" className={location?.pathname === "/applications" ? styles.activeRoute : ''} variant="menuMain" align="left">Заявки</TextUI>
              </div>

              <div className={styles.settingsItem} onClick={handleSettings} >
                <div className={styles.iconWrapper}>
                  <HeaderSettingsIcon active={location.pathname === '/settings'} />
                </div>
                <TextUI color="secondary" className={location?.pathname === "/settings" ? styles.activeRoute : ''} variant="menuMain" align="left">Настройки</TextUI>
              </div>

              <div className={styles.horizontalLine}></div>

              <div className={styles.settingsItem} onClick={handleLogout}>
                <LogoutIcon />
                <p className={styles.menuMain} >ВЫЙТИ</p>
              </div>
            </div>
          )}

          {/* Десктопное меню */}
          {isAuth && <div className={`${styles.headerRightSide} ${showDesktopSettings ? styles.openModal : ''} ${styles.desktopMenu}`}>
            <div className={styles.headerRightSideLeft}>
              <p>{user?.email}</p>
              <p className={styles.idText}>id{user?.userId}</p>
            </div>
            {!showDesktopSettings && location.pathname !== '/request-card' && <img src={profiArrow} onClick={handleOpenDesktopModal} className={styles.arrow} />}
            {showDesktopSettings && location.pathname !== '/request-card' && <img src={ArrowGray} onClick={handleOpenDesktopModal} className={styles.arrow} />}
            {location.pathname === '/request-card' && <img src={buttonHome} alt="Home" className={styles.arrow} onClick={handleHomeClick} />}

            {showDesktopSettings && location.pathname !== '/request-card' && (
              <SettingsModal ref={menuRef} handleLogout={handleLogout} />
            )}
          </div>}
        </div>
        <ModalWithChildren
          isOpen={isHomeModalOpen}
          onClose={() => setIsHomeModalOpen(false)}
        >
          <div className={styles.modalContent}>
            <p>Вы уверены, что хотите остановить оформление заявки?</p>
            <div className={styles.modalButtons}>
              <ButtonUI
                label="Да"
                variant="main"
                width='53px'
                height='42px'
                onClick={() => {
                  setIsHomeModalOpen(false);
                  navigate('/main');
                }}
              />
              <ButtonUI
                label="Нет"
                variant="main2"
                width='53px'
                height='42px'
                onClick={() => setIsHomeModalOpen(false)}
              />
            </div>
          </div>
        </ModalWithChildren>
      </MainContainer>

      {showMobileSettings && (
        <SettingsModal handleLogout={handleLogout} className={styles.mobileSettingsModal} ref={menuRef} />
      )}
    </header>
  );
};

export default MainHeader;
