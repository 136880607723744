import React, { useEffect } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import { LoginPage } from 'pages/LoginPage';
import { RegistrationPage } from 'pages/RegistrationPage';
import { RegistrationWithContactAgentPage } from 'pages/RegistrationWithContactAgentPage';
import UIComponentsPage from 'pages/UiComponentsPage/UIComponentsPage';
import OffertLaw from 'pages/offert-law/offert-law';
import { ApproveRegistrationCodePage } from 'pages/ApproveRegistrationCodePage';
import { RegistrationDonePage } from 'pages/RegistrationDonePage';
import PrivacyPolicy from 'pages/PrivacyPolicy/PrivacyPolicy';
import { RecoveryPasswordDonePage } from 'pages/RecoveryPasswordDonePage';
import { RecoveryPasswordPage } from 'pages/RecoveryPasswordPage';
import { RecoveryPasswordCheckPage } from 'pages/RecoveryPasswordCheckPage';
import { RecoveryPasswordFormPage } from 'pages/RecoveryPasswordFormPage';
import PrivateRoute from 'app/privateRoute/PrivateRoute';
import { SettingsPage } from 'pages/SettingsPage';
import OffersPage from 'pages/OffersPage/ui/OffersPage';
import DailyStatistic from 'pages/DailyStatistic/DailyStatistic';
import StatisticByOffers from 'pages/StatisticByOffers/StatisticByOffers';
import Applications from 'pages/Applications/Applications';
import NotFoundPage from 'pages/NotFound/notFound';
import { RequestCardPage } from 'pages/RequestCardPage';
import AuthorizeWithContactAgentPage from 'pages/AuthorizeWithContactAgentPage/ui/AuthorizeWithContactAgentPage';
import { MainPage } from 'pages/Main';
import OfferDetailsPage from 'pages/OfferDetailsPage/OfferDetailsPage';
import { PublicBidPage } from 'pages/PublicBidPage';
import { useStorageListener } from 'hooks/useStorageListener';
import RequestCardLayout from './layouts/RequestCardLayout/RequestCardLayout';

const AppContent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuth = useSelector((state: RootState) => state.login.isAuth);
  const currentPath = location.pathname;

  useStorageListener(); // Добавляем прослушивание изменений localStorage

  useEffect(() => {
    if (isAuth && currentPath === '/login') {
      navigate('/main');
    }
  }, [navigate, isAuth, currentPath]);

  return (
    <Routes>
      <Route path='/' element={<Navigate to="/registration" />} />
      {/* Публичные маршруты */}
      <Route path='/login' element={<LoginPage />} />
      <Route path='/recovery-password' element={<RecoveryPasswordPage />} />
      <Route path='/recovery-password-check' element={<RecoveryPasswordCheckPage />} />
      <Route path='/recovery-password-done' element={<RecoveryPasswordDonePage />} />
      <Route path="/bid/pub/:hash" element={<PublicBidPage />} />
      <Route path='/registration' element={<RegistrationPage />} />
      <Route
        path='/approve-registration-code'
        element={<ApproveRegistrationCodePage />}
      />
      <Route
        path='/registration-done'
        element={<RegistrationDonePage />}
      />
      <Route path="/registration-with-contact-agent" element={<RegistrationWithContactAgentPage />} />
      <Route path="/authorize-with-contact-agent" element={<AuthorizeWithContactAgentPage />} />
      <Route
        path='/recovery-password-form/:recoverPasswordLink/:encodedEmail/:timestamp'
        element={<RecoveryPasswordFormPage />}
      />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path='/user-agreement' element={<OffertLaw />} />
      <Route path="/ui-components" element={<UIComponentsPage />} />
      {/* Защищенные маршруты */}
      <Route element={<PrivateRoute />}>
        <Route path='/main' element={<MainPage />} />
        <Route path='/settings' element={<SettingsPage />} />
        <Route path='/offers' element={<OffersPage />} />
        <Route path='/offers/:id' element={<OfferDetailsPage />} />
        <Route path='/daily-statistic' element={<DailyStatistic />} />
        <Route path='/statistic-by-offers' element={<StatisticByOffers />} />
        <Route path='/applications' element={<Applications />} />
        <Route path='/request-card' element={<RequestCardPage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
