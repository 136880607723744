import React from 'react';
import classNames from 'classnames'; // Импортируем classnames
import { TextUIProps } from '../types/TextUI.types';

import styles from './TextUI.module.scss';

export const TextUI: React.FC<TextUIProps> = ({
    variant = 'bodyM',
    color = 'general',
    align = 'left',
    className = '', // Добавляем возможность передать кастомный класс
    children,
    fullWidth = true,
}) => {
    const textClass = classNames(
        styles[variant],
        styles[color],
        styles[align],
        { [styles.fullWidth]: fullWidth },
        className // Объединяем кастомный класс с существующими
    );

    return <p className={textClass}>{children}</p>;
};
