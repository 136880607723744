import React, { useEffect, useState, useRef } from 'react';
import { PepConsentPhotoForm } from 'components/PepConsentPhotoForm';
import { useGetCardRequestByIdQuery, useGetBidStatusQuery, useGeneratePepMutation } from 'services/api';
import { skipToken } from '@reduxjs/toolkit/query/react';

import styles from './Step8.module.scss';

const POLLING_INTERVAL = 3000; // 3 секунды
const MAX_POLLING_ATTEMPTS = 20; // Максимальное количество попыток

interface Step8Props {
    setIsFormValid: (isValid: boolean) => void;
    onFormSubmit: (data: any) => void;
    requestId: string | null;
    onStatusChange?: (status: number | undefined) => void;
}

const Step8: React.FC<Step8Props> = ({ setIsFormValid, onFormSubmit, requestId, onStatusChange }) => {
    const { data: cardRequestData, refetch } = useGetCardRequestByIdQuery(requestId);
    console.log("🚀 ~ file: Step8.tsx:20 ~ cardRequestData:", cardRequestData)
    const [generatePep] = useGeneratePepMutation();
    const [generateCreditCardPep] = useGeneratePepMutation();
    const [isPhotoValid, setIsPhotoValid] = useState(false);
    const [isPhotoUploaded, setIsPhotoUploaded] = useState(false);
    const [isPolling, setIsPolling] = useState(false);
    const [pollingCount, setPollingCount] = useState(0);
    const [statusMessage, setStatusMessage] = useState<string | null>(null);
    const [messageType, setMessageType] = useState<'success' | 'error'>('error');
    const [isFormEnabled, setIsFormEnabled] = useState(false);
    const prevStatusRef = useRef<any>(null);

    // Запрос статуса с поллингом
    const { data: statusData } = useGetBidStatusQuery(
        requestId ? { id: requestId, type: 'generate_pep' } : skipToken,
        {
            pollingInterval: isPolling ? POLLING_INTERVAL : 0,
            refetchOnMountOrArgChange: true
        }
    );

    // Проверяем текущий статус
    const currentStatus = statusData?.message?.partnerResponseCode;

    // При изменении статуса обновляем данные
    useEffect(() => {
        if (currentStatus !== prevStatusRef.current) {
            prevStatusRef.current = currentStatus;
            refetch();
        }
    }, [currentStatus, refetch]);

    // При монтировании компонента проверяем текущий статус
    useEffect(() => {
        const checkAndGeneratePep = async () => {
            if (!requestId) return;


            // Генерируем PEP только если статус равен -1
            if (currentStatus === -1) {
                try {
                    setIsPolling(true);
                    // await generatePep(requestId).unwrap();
                    await generateCreditCardPep(requestId).unwrap();
                    setStatusMessage('Запрос на генерацию PEP отправлен');
                    setMessageType('success');
                } catch (error) {
                    setStatusMessage('Ошибка при отправке запроса на генерацию PEP');
                    setIsPolling(false);
                }
            }
        };

        checkAndGeneratePep();
    }, [requestId, generatePep, statusData?.message?.partnerResponseCode]);

    // Обработка статуса колбека
    useEffect(() => {
        if (!statusData) return;

        const { partnerResponseCode, partnerResponseMessage } = statusData.message || {};

        // Увеличиваем счетчик только если статус изменился и это не код -1 (ожидание)
        if (prevStatusRef.current?.message?.partnerResponseCode !== partnerResponseCode && partnerResponseCode !== -1) {
            setPollingCount(prev => prev + 1);
            prevStatusRef.current = statusData;
        }

        if (partnerResponseCode !== undefined) {
            switch (partnerResponseCode) {
                case -1: // Банк еще не ответил
                    setStatusMessage(statusData.message.hint || 'Ожидание ответа от банка...');
                    setMessageType('error');
                    setIsFormEnabled(false);
                    break;
                case 0: // Успех
                    setIsPolling(false);
                    setStatusMessage('PEP успешно сгенерирован, загрузите файлы согласия на ПЭП'); //statusData.message.hint || 
                    setMessageType('success');
                    setIsFormEnabled(true);
                    break;
                case 1: // Техническая ошибка
                    setIsPolling(false);
                    setStatusMessage(statusData.message.hint || 'Необходимо отменить заявку.');
                    setMessageType('error');
                    setIsFormEnabled(false);
                    break;
                case 2: // Отказ
                    setIsPolling(false);
                    setStatusMessage(statusData.message.hint || 'Отказано в генерации PEP');
                    setMessageType('error');
                    setIsFormEnabled(false);
                    break;
                case 6: // ПЭП не требуется
                    setIsPolling(false);
                    setStatusMessage(statusData.message.hint || 'Подписание ПЭП не требуется. Ожидайте подписания документов клиентом.');
                    setMessageType('success');
                    setIsFormEnabled(false);
                    break;
                case 7: // Отмена
                    setIsPolling(false);
                    setStatusMessage(statusData.message.hint || 'Генерация PEP отменена');
                    setMessageType('error');
                    setIsFormEnabled(false);
                    break;
                default:
                    setIsPolling(false);
                    setStatusMessage(statusData.message.hint || 'Неизвестная ошибка');
                    setMessageType('error');
                    setIsFormEnabled(false);
            }
        } else if (pollingCount >= MAX_POLLING_ATTEMPTS) {
            setIsPolling(false);
            setStatusMessage('Превышено время ожидания генерации PEP');
            setIsFormEnabled(false);
        }
    }, [statusData, pollingCount]);

    // При изменении статуса уведомляем родительский компонент
    useEffect(() => {
        if (onStatusChange) {
            onStatusChange(statusData?.message?.partnerResponseCode);
        }
    }, [statusData?.message?.partnerResponseCode, onStatusChange]);

    // Обновляем валидность формы на основе загрузки фото
    useEffect(() => {
        setIsFormValid(isPhotoValid);
    }, [isPhotoValid, setIsFormValid]);

    // Обработчик успешной загрузки фото
    const handlePhotoUploadSuccess = () => {
        setIsPhotoUploaded(true);
        setIsPhotoValid(true);
        setStatusMessage('Фото успешно загружены');
    };

    const handleFormSubmit = async () => {
        if (onFormSubmit) {
            await onFormSubmit({});
        }
    };

    if (!requestId) {
        return null;
    }

    return (
        <PepConsentPhotoForm
            requestId={requestId}
            setIsFormValid={setIsPhotoValid}
            onFormSubmit={handleFormSubmit}
            serverData={cardRequestData}
            disabled={!isFormEnabled}
            onUploadSuccess={handlePhotoUploadSuccess}
            statusMessage={statusMessage as string}
            messageType={messageType}
            statusCode={statusData?.message?.partnerResponseCode}
        />
    );
};

export default Step8;
