import React, { useMemo, useState, useEffect } from 'react';
import { useGetApplicationsQuery } from 'services/api';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';
import LoadingOverlay from 'components/ui/LoadingOverlay/LoadingOverlay';
import { SingleSelectSearch } from 'components/ui/SingleSelectSearch/ui/SingleSelectSearch';
import { OptionType } from 'components/Offers/model/types';
import Table from 'components/ui/Table/ui/Table';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import { useMediaQuery } from 'shared/hooks/UseMediaQuery';
import FiltersIcon from 'assets/icons/FiltersIcon';
import { MobileFilter } from 'components/ui/MobileFilter';
import CustomCalendarSelect from 'components/ui/CustomCalendarSelect/ui/CustomCalendarSelect';

import styles from './OffersMainList.module.scss';
import { DualCalendarSelector } from 'components/ui/DualCalendarSelector';

interface OfferItem {
    offerId: string;
    offerName: string;
    category: string;
    date: string;
    totalApplications: number;
    confirmedApplications: number;
}

const OffersMainList: React.FC = () => {
    const { data: applications = [], error, isLoading } = useGetApplicationsQuery({ page: 1 });

    const isMobile = useMediaQuery(768);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedOffer, setSelectedOffer] = useState<string | null>(null);
    const [resetSelect, setResetSelect] = useState(false);
    const [filteredOffers, setFilteredOffers] = useState<OfferItem[]>([]);

    const getDefaultRange = (): [Date, Date] => {
        const today = new Date();
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(today.getDate() - 150);
        return [sevenDaysAgo, today];
    };

    const [dateRange, setDateRange] = useState<[Date, Date]>(getDefaultRange());
    const [resetDates, setResetDates] = useState(false);

    // Transform applications data into the required format
    const transformedData = useMemo(() => {
        if (!applications.length) return [];

        // Group applications by offer
        const offerStats = applications.reduce((acc: OfferItem[], app: any) => {
            const existingOffer = acc.find(
                offer => offer.offerId === app.offer.id
            );

            if (existingOffer) {
                existingOffer.totalApplications += 1;
                if (app.status === 'approved') {
                    existingOffer.confirmedApplications += 1;
                }
            } else {
                acc.push({
                    offerId: app.offer.id,
                    offerName: app.offer.name,
                    category: app.offer.category,
                    date: app.date,  // Make sure we're using the correct date field
                    totalApplications: 1,
                    confirmedApplications: app.status === 'approved' ? 1 : 0
                });
            }

            return acc;
        }, []);

        return offerStats;
    }, [applications]);

    const offerOptions = useMemo(() => {
        const uniqueOffers = Array.from(new Set(transformedData.map(offer => offer.offerName)));
        return uniqueOffers.map(offerName => ({
            label: offerName,
            value: offerName
        }));
    }, [transformedData]);

    useEffect(() => {
        let filtered = [...transformedData]; // Создаем копию данных

        if (dateRange) {
            const [startDate, endDate] = dateRange;
            const start = new Date(startDate);
            const end = new Date(endDate);

            filtered = filtered.filter((item: OfferItem) => {
                const itemDate = new Date(item.date);
                return itemDate >= start && itemDate <= end;
            });
        }

        if (selectedOffer) {
            filtered = filtered.filter((item: OfferItem) => item.offerName === selectedOffer);
        }

        // Проверяем, изменился ли результат фильтрации
        if (
            JSON.stringify(filtered) !== JSON.stringify(filteredOffers)
        ) {
            setFilteredOffers(filtered); // Обновляем состояние только если данные изменились
        }
    }, [dateRange, selectedOffer, transformedData, filteredOffers]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'offerId',
                header: 'Оффер ID',
                cell: ({ row }: any) => <span>{row.original.offerId}</span>,
            },
            {
                accessorKey: 'offerName',
                header: 'Оффер',
                cell: ({ row }: any) => (
                    <div className={styles.offerCell}>
                        <div>
                            <div>{row.original.offerName}</div>
                            <div className={styles.subTitle}>{row.original.category}</div>
                        </div>
                    </div>
                ),
            },
            {
                accessorKey: 'totalApplications',
                header: 'Всего заявок',
                cell: ({ row }) => <span>{row.original.totalApplications}</span>,
            },
            {
                accessorKey: 'confirmedApplications',
                header: 'Подтвержденные заявки',
                cell: ({ row }) => <span>{row.original.confirmedApplications}</span>,
            },
        ],
        []
    );

    const handleOfferSelect = (selected: OptionType | null) => {
        if (selected) {
            const selectedValue = typeof selected.value === 'string' ? selected.value : String(selected.value);
            setSelectedOffer(selectedValue);
        } else {
            setSelectedOffer(null);
        }
        setResetSelect(false);
    };

    const handleReset = () => {
        const defaultRange = getDefaultRange();
        setSelectedOffer(null);
        setResetSelect(true);
        setDateRange(defaultRange);
        setFilteredOffers(transformedData);
        localStorage.setItem('dateRange', JSON.stringify(defaultRange));
        setTimeout(() => setResetDates(false), 100);
    };

    const handleDateChange = (range: [Date, Date]) => {
        setDateRange(range);
        localStorage.setItem('dateRange', JSON.stringify(range));
    };

    // const renderCardView = () => (
    //     <div className={styles.cardsContainer}>
    //         {filteredOffers.map((offer: OfferItem) => (
    //             <div key={offer.offerId} className={styles.card}>
    //                 <div className={styles.cardRow}>
    //                     <div className={styles.cardContent}>
    //                         <TextUI variant='h2'>{offer.offerName}</TextUI>
    //                         <span>{offer.category}</span>
    //                     </div>
    //                 </div>
    //                 <ButtonUI
    //                     label='Подробнее'
    //                     variant='main2'
    //                     height='42px'
    //                     width='120px'
    //                 />
    //             </div>
    //         ))}
    //     </div>
    // );

    if (isLoading) return <LoadingOverlay />;
    if (error) return <div>Ошибка при загрузке статистики по офферам</div>;

    return (
        <div className={styles.offersList}>
            <TextUI variant='h1' className={styles.marginBottom}>
                Топ офферов
            </TextUI>
            {/* && filteredOffers.length > 0 */}
            {isMobile && (
                <div className={styles.filterMenu} onClick={() => setIsModalOpen(true)}>
                    <FiltersIcon />
                </div>
            )}

            {isMobile && isModalOpen && (
                <MobileFilter
                    title='Параметры'
                    onClose={() => setIsModalOpen(false)}
                    onShow={() => setIsModalOpen(false)}
                    onReset={handleReset}
                >
                    <div className={styles.mobileFilters}>
                        {/* <CustomCalendarSelect
                            dateRange={dateRange}
                            onDateChange={handleDateChange}
                            resetDates={resetDates}
                        /> */}
                        <DualCalendarSelector
                            dateRange={dateRange}
                            onDateChange={handleDateChange}
                            resetDates={resetDates}
                            showDoubleView={false}
                        />
                        <SingleSelectSearch
                            options={offerOptions}
                            onOptionSelect={handleOfferSelect}
                            placeholder="Все"
                            reset={resetSelect}
                        />
                    </div>
                </MobileFilter>
            )}
            {!isMobile && (
                <div className={styles.filters}>
                    <div className={styles.twoFilters}>
                        {/* <CustomCalendarSelect
                            dateRange={dateRange}
                            onDateChange={handleDateChange}
                            resetDates={resetDates}
                            showDoubleView={true}
                        /> */}
                        <DualCalendarSelector
                            dateRange={dateRange}
                            onDateChange={handleDateChange}
                            resetDates={resetDates}
                        />

                        <SingleSelectSearch
                            options={offerOptions}
                            onOptionSelect={handleOfferSelect}
                            placeholder="Все"
                            reset={resetSelect}
                            title='Оффер'
                            simpleSelect
                        />
                    </div>
                    <ButtonUI
                        variant='secondary'
                        label='Сбросить фильтр'
                        onClick={handleReset}
                        height='42px'
                    />
                </div>
            )}
            {/* {isMobile ? renderCardView() : (
                <Table
                    data={filteredOffers}
                    columns={columns}
                />
            )} */}

            <Table
                data={filteredOffers}
                columns={columns}
            />

        </div>
    );
};

export default OffersMainList;
