import React from 'react';
import styles from './SliderSwitch.module.scss';
import { useUpdateBidSandboxMutation } from '../../../services/api';

interface SliderSwitchProps {
    bidId: string;
    initialSandbox?: boolean;
    onChange?: (isChecked: boolean) => void;
}

const SliderSwitch: React.FC<SliderSwitchProps> = ({ bidId, initialSandbox = false, onChange }) => {
    const [isChecked, setIsChecked] = React.useState(initialSandbox);
    const [isLoading, setIsLoading] = React.useState(false);
    const [updateSandbox] = useUpdateBidSandboxMutation();

    const handleChange = async (newValue: boolean) => {
        if (isLoading) return; // Предотвращаем повторные запросы во время загрузки
        
        try {
            setIsLoading(true);
            await updateSandbox({ id: bidId, sandbox: newValue }).unwrap();
            setIsChecked(newValue); // Обновляем UI только после успешного ответа
            onChange?.(newValue);
        } catch (error) {
            console.error('Ошибка при обновлении sandbox статуса:', error);
            // Не меняем состояние, так как мы его еще не изменили
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={styles.sliderContainer}>
            <div
                className={`${styles.option} ${!isChecked ? styles.active : ''} ${isLoading ? styles.loading : ''}`}
                onClick={() => handleChange(false)}
            >
                ЗАЯВКА
            </div>
            <div
                className={`${styles.option} ${isChecked ? styles.active : ''} ${isLoading ? styles.loading : ''}`}
                onClick={() => handleChange(true)}
            >
                ОБУЧЕНИЕ
            </div>
        </div>
    );
};

export default SliderSwitch;
