import React from 'react';

const CancelIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M16.0345 14.0001L22.1353 7.89931C22.6968 7.33773 22.6968 6.42722 22.1353 5.86572C21.5737 5.30414 20.6633 5.30414 20.1017 5.86572L14.0008 11.9666L7.90002 5.86564C7.33844 5.30406 6.42802 5.30406 5.86644 5.86564C5.30494 6.42722 5.30494 7.33773 5.86644 7.89923L11.9673 14L5.86644 20.1008C5.30494 20.6624 5.30494 21.5729 5.86644 22.1344C6.42802 22.696 7.33844 22.696 7.90002 22.1344L14.0008 16.0336L20.1017 22.1344C20.6632 22.696 21.5737 22.696 22.1353 22.1344C22.6968 21.5728 22.6968 20.6624 22.1353 20.1008L16.0345 14.0001Z" fill="#2F2F33" />
    </svg>
  );
};

export default CancelIcon;
