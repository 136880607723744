import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { CustomInput } from 'components/CustomInput/CustomInput';
import { InputTypeEnum } from 'shared/models';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import { RadioButton } from 'components/Forms/RadioButton';
import { CardWrapper } from 'components/CardWrapper/CardWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { savePersonalData } from 'features/request/requestSlice';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import styles from './PersonalDataForm.module.scss';

interface PersonalDataFormProps {
  setIsFormValid: (isValid: boolean) => void;
  onFormSubmit: (data: any) => void;
  defaultValues: any;
}

const validationSchema = yup.object({
  name: yup.string()
    .required('Поле обязательно для заполнения')
    .matches(/^[А-Яа-яЁёA-Za-z\s]+$/, 'ФИО должно содержать только буквы и пробелы')
    .min(2, 'ФИО должно быть не менее 2 символов')
    .max(100, 'ФИО должно быть не более 100 символов'),
  birthDate: yup.string()
    .required('Поле обязательно для заполнения')
    .test('valid-date', 'Некорректная дата', (value) => {
      if (!value) return false;
      const date = new Date(value);
      return date instanceof Date && !isNaN(date.getTime());
    })
    .test('min-age', 'Возраст должен быть не менее 18 лет', (value) => {
      if (!value) return false;
      const date = new Date(value);
      const today = new Date();
      const age = today.getFullYear() - date.getFullYear();
      const monthDiff = today.getMonth() - date.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < date.getDate())) {
        return age - 1 >= 18;
      }
      return age >= 18;
    })
    .test('max-age', 'Возраст не может превышать 100 лет', (value) => {
      if (!value) return false;
      const date = new Date(value);
      const today = new Date();
      const age = today.getFullYear() - date.getFullYear();
      return age <= 100;
    }),
  gender: yup.string()
    .required('Поле обязательно для заполнения')
    .oneOf(['male', 'female'], 'Выберите пол'),
  email: yup.string()
    .required('Поле обязательно для заполнения')
    .email('Некорректный email')
    .max(100, 'Email должен быть не более 100 символов'),
  phone: yup.string()
    .required('Поле обязательно для заполнения')
    .matches(/^\+?[0-9]{11}$/, 'Некорректный формат телефона'),
  passportNumber: yup.string()
    .required('Поле обязательно для заполнения')
    .matches(/^\d{4}\s?\d{6}$/, 'Некорректный формат серии и номера паспорта'),
  birthPlace: yup.string()
    .required('Поле обязательно для заполнения')
    .min(2, 'Место рождения должно быть не менее 2 символов')
    .max(200, 'Место рождения должно быть не более 200 символов'),
  issueDate: yup.string()
    .required('Поле обязательно для заполнения')
    .test('valid-date', 'Некорректная дата', (value) => {
      if (!value) return false;
      const date = new Date(value);
      return date instanceof Date && !isNaN(date.getTime());
    })
    .test('not-future', 'Дата выдачи не может быть в будущем', (value) => {
      if (!value) return false;
      const date = new Date(value);
      const today = new Date();
      return date <= today;
    })
    // .test('not-too-old', 'Паспорт должен быть выдан не более 20 лет назад', (value) => {
    //   if (!value) return false;
    //   const date = new Date(value);
    //   const today = new Date();
    //   const yearDiff = today.getFullYear() - date.getFullYear();
    //   return yearDiff <= 20;
    // })
    .test('after-14-years', 'Паспорт не может быть выдан ранее 14-летия', function (value) {
      if (!value) return false;
      const issueDate = new Date(value);
      const birthDate = new Date(this.parent.birthDate);
      const age = issueDate.getFullYear() - birthDate.getFullYear();
      const monthDiff = issueDate.getMonth() - birthDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && issueDate.getDate() < birthDate.getDate())) {
        return age - 1 >= 14;
      }
      return age >= 14;
    }),
  departmentCode: yup.string()
    .required('Поле обязательно для заполнения')
    .matches(/^\d{3}-\d{3}$/, 'Некорректный формат кода подразделения'),
  issuedBy: yup.string()
    .required('Поле обязательно для заполнения')
    .min(2, 'Поле должно быть не менее 2 символов')
    .max(200, 'Поле должно быть не более 200 символов'),
  registrationAddress: yup.string()
    .required('Поле обязательно для заполнения')
    .min(5, 'Адрес должен быть не менее 5 символов')
    .max(200, 'Адрес должен быть не более 200 символов'),
  actualAddress: yup.string()
    .when('addressMatches', {
      is: 'no',
      then: (schema) => schema
        .required('Поле обязательно для заполнения')
        .min(5, 'Адрес должен быть не менее 5 символов')
        .max(200, 'Адрес должен быть не более 200 символов')
    }),
  registrationType: yup.string()
    .required('Поле обязательно для заполнения')
    .oneOf(['permanent', 'temporary'], 'Выберите тип регистрации'),
  addressMatches: yup.string()
    .required('Поле обязательно для заполнения')
    .oneOf(['yes', 'no'], 'Выберите значение')
});

export const PersonalDataForm: React.FC<PersonalDataFormProps> = ({
  setIsFormValid,
  onFormSubmit,
  defaultValues,
}) => {
  const dispatch = useDispatch();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: {
      name: defaultValues?.name || '',
      birthDate: defaultValues?.birthDate || '',
      gender: defaultValues?.gender || 'male',
      email: defaultValues?.email || '',
      phone: defaultValues?.phone || '',
      passportNumber: defaultValues?.passportNumber || '',
      birthPlace: defaultValues?.birthPlace || '',
      issueDate: defaultValues?.issueDate || '',
      departmentCode: defaultValues?.departmentCode || '',
      issuedBy: defaultValues?.issuedBy || '',
      registrationType: defaultValues?.registrationType || 'permanent',
      addressMatches: defaultValues?.addressMatches || 'no',
      registrationAddress: defaultValues?.registrationAddress || '',
      actualAddress: defaultValues?.actualAddress || '',
    },
  });

  const {
    handleSubmit,
    watch,
    formState: { errors, isValid },
    setValue,
  } = methods;

  const addressMatches = watch('addressMatches') === 'yes';

  useEffect(() => {
    setIsFormValid(isValid);
  }, [isValid, setIsFormValid]);

  useEffect(() => {
    const subscription = watch((value) => {
      dispatch(savePersonalData(value));
    });
    return () => subscription.unsubscribe();
  }, [watch, dispatch]);

  const onSubmit = (data: any) => {
    onFormSubmit(data);
  };

  const handleAddressMatchesChange = (value: string) => {
    setValue('addressMatches', value);
    if (value === 'yes') {
      setValue('actualAddress', watch('registrationAddress'));
    }
  };

  return (
    <FormProvider {...methods}>
      <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.flexRow}>
          <CardWrapper title="Персональные данные">
            <CustomInput
              name="name"
              type={InputTypeEnum.FIO}
              placeholder="ФАМИЛИЯ ИМЯ ОТЧЕСТВО"
              description="Укажите точно как в паспорте"
              validate={(value) => /^[А-Яа-яЁёA-Za-z\s]+$/.test(value) || 'ФИО должно содержать только буквы и пробелы'}
              required
              hideIcon
            />
            <CustomInput
              name="birthDate"
              type={InputTypeEnum.Date}
              placeholder="ДАТА РОЖДЕНИЯ"
              required
              hideIcon
            />
            <TextUI color="secondary" variant="bodyS">
              ПОЛ
            </TextUI>

            <div className={styles.genderWrapper}>
              <RadioButton
                name="gender"
                label="Мужской"
                value="male"
                checked={watch('gender') === 'male'}
                onChange={() => setValue('gender', 'male')}
              />
              <RadioButton
                name="gender"
                label="Женский"
                value="female"
                checked={watch('gender') === 'female'}
                onChange={() => setValue('gender', 'female')}
              />
            </div>
            <CustomInput
              name="email"
              type={InputTypeEnum.Email}
              placeholder="ЭЛЕКТРОННАЯ ПОЧТА"
              required
              hideIcon
            />
            <CustomInput
              name="phone"
              type={InputTypeEnum.Tel}
              placeholder="МОБИЛЬНЫЙ ТЕЛЕФОН"
              required
              hideIcon
            />
          </CardWrapper>

          <div className={styles.column}>
            <TextUI variant="h2">Паспортные данные</TextUI>
            <CustomInput
              name="passportNumber"
              type={InputTypeEnum.Text}
              placeholder="СЕРИЯ И НОМЕР ПАСПОРТА"
              description="1234 567890"
              required
              hideIcon
            />
            <CustomInput
              name="birthPlace"
              type={InputTypeEnum.Text}
              placeholder="МЕСТО РОЖДЕНИЯ"
              description="Укажите точно как в паспорте"
              required
              hideIcon
            />
            <CustomInput
              name="issueDate"
              type={InputTypeEnum.Date}
              placeholder="ДАТА ВЫДАЧИ"
              required
              hideIcon
            />
            <CustomInput
              name="departmentCode"
              type={InputTypeEnum.Text}
              placeholder="КОД ПОДРАЗДЕЛЕНИЯ"
              description="123-456"
              required
              hideIcon
            />
            <CustomInput
              name="issuedBy"
              type={InputTypeEnum.Text}
              placeholder="КЕМ ВЫДАН"
              description="Укажите точно как в паспорте"
              required
              hideIcon
            />
          </div>
        </div>

        <div className={styles.column}>
          <TextUI variant="h2">Адрес регистрации</TextUI>
          <CustomInput
            name="registrationAddress"
            type={InputTypeEnum.Text}
            placeholder="АДРЕС РЕГИСТРАЦИИ"
            description="Город, улица, дом, корпус и квартира"
            required
            hideIcon
          />

          <TextUI color="secondary" variant="bodyS">
            РЕГИСТРАЦИЯ
          </TextUI>
          <div className={styles.radioGroup}>
            <RadioButton
              name="registrationType"
              label="Постоянная"
              value="permanent"
              checked={watch('registrationType') === 'permanent'}
              onChange={() => setValue('registrationType', 'permanent')}
            />
            <RadioButton
              name="registrationType"
              label="Временная"
              value="temporary"
              checked={watch('registrationType') === 'temporary'}
              onChange={() => setValue('registrationType', 'temporary')}
            />
          </div>

          <TextUI color="secondary" variant="bodyS">
            ФАКТИЧЕСКИЙ АДРЕС ПРОЖИВАНИЯ СОВПАДАЕТ С АДРЕСОМ РЕГИСТРАЦИИ?
          </TextUI>
          <div className={styles.radioGroup}>
            <RadioButton
              name="addressMatches"
              label="Да"
              value="yes"
              checked={addressMatches}
              onChange={() => handleAddressMatchesChange('yes')}
            />
            <RadioButton
              name="addressMatches"
              label="Нет"
              value="no"
              checked={!addressMatches}
              onChange={() => handleAddressMatchesChange('no')}
            />
          </div>

          {!addressMatches && (
            <CustomInput
              name="actualAddress"
              type={InputTypeEnum.Text}
              placeholder="ФАКТИЧЕСКИЙ АДРЕС ПРОЖИВАНИЯ"
              description="Город, улица, дом, корпус и квартира"
              required={!addressMatches}
              hideIcon
            />
          )}
        </div>
      </form>
    </FormProvider>
  );
};
