import React, { useEffect, useState, useRef } from 'react';
import { useGetBidStatusQuery, useGenerateDocumentsMutation } from 'services/api';
import { skipToken } from '@reduxjs/toolkit/query/react';
import TelegramIcon from 'assets/icons/TelegramIcon';
import Loader from 'components/Loader/Loader';

import styles from './Step9.module.scss';

const POLLING_INTERVAL = 3000; // 3 секунды
const MAX_POLLING_ATTEMPTS = 20; // Максимальное количество попыток

interface Step9Props {
    requestId: string | null;
    onStatusChange?: (status: number | undefined) => void;
    setIsFormValid: (isValid: boolean) => void;
}

interface StatusData {
    message?: {
        partnerResponseCode: number;
    };
}

interface StatusResponse {
    message?: {
        partnerResponseCode: number;
    };
}

const Step9: React.FC<Step9Props> = ({ requestId, onStatusChange, setIsFormValid }) => {
    const [generateDocuments] = useGenerateDocumentsMutation();
    const [isPolling, setIsPolling] = useState(false);
    const [pollingCount, setPollingCount] = useState(0);
    const [statusMessage, setStatusMessage] = useState<string>('Формируется пакет документов для подписания');
    const [messageType, setMessageType] = useState<'success' | 'error'>('error');
    const prevStatusRef = useRef<StatusData | null>(null);
    const [prevPartnerResponseCode, setPrevPartnerResponseCode] = useState<number | null>(null);

    // Запрос статуса с поллингом
    const { data: statusData } = useGetBidStatusQuery(
        requestId ? { id: requestId, type: 'generate_documents' } : skipToken,
        {
            pollingInterval: isPolling ? POLLING_INTERVAL : 0,
            refetchOnMountOrArgChange: true
        }
    );

    // Проверяем текущий статус
    const currentStatus = statusData?.message?.partnerResponseCode;

    // При монтировании компонента проверяем текущий статус
    useEffect(() => {
        const checkAndGenerateDocuments = async () => {
            if (!requestId) return;


            // Генерируем документы только если статус равен -1
            if (currentStatus === -1) {
                try {
                    setIsPolling(true);
                    await generateDocuments(requestId).unwrap();
                    setStatusMessage('Запрос на генерацию документов отправлен');
                    setMessageType('success');
                } catch (error) {
                    setStatusMessage('Ошибка при отправке запроса на генерацию документов');
                    setIsPolling(false);
                }
            }
        };

        checkAndGenerateDocuments();
    }, [requestId, generateDocuments, statusData?.message?.partnerResponseCode]);

    // Обработка статуса колбека
    useEffect(() => {
        if (!statusData) return;

        const { partnerResponseCode, partnerResponseMessage } = statusData.message || {};

        // Увеличиваем счетчик только если статус изменился и это не код -1 (ожидание)
        if (prevPartnerResponseCode !== partnerResponseCode && partnerResponseCode !== -1) {
            setPollingCount(prev => prev + 1);
            prevStatusRef.current = statusData;
            setPrevPartnerResponseCode(partnerResponseCode);
        }

        if (partnerResponseCode !== undefined) {
            onStatusChange?.(partnerResponseCode);

            switch (partnerResponseCode) {
                case -1: // Банк еще не ответил
                    setStatusMessage(statusData.message.hint || 'Ожидание ответа от банка...');
                    setMessageType('error');
                    break;
                case 0: // Успех
                    setIsPolling(false);
                    setStatusMessage('Банк отправил Клиенту СМС с пакетом документов для подписания на открытие счета и кредитного договора');
                    setMessageType('success');
                    break;
                default: // Ошибка
                    setIsPolling(false);
                    setStatusMessage(partnerResponseMessage || 'Произошла ошибка при генерации документов');
                    setMessageType('error');
                    break;
            }
        }

        // Останавливаем поллинг после MAX_POLLING_ATTEMPTS попыток
        if (pollingCount >= MAX_POLLING_ATTEMPTS) {
            setIsPolling(false);
            setStatusMessage('Превышено максимальное количество попыток получения статуса');
            setMessageType('error');
        }
    }, [statusData, pollingCount, onStatusChange]);

    useEffect(() => {
        if (statusData?.message?.partnerResponseCode !== undefined) {
            onStatusChange?.(statusData.message.partnerResponseCode);
            setIsFormValid(statusData.message.partnerResponseCode === 0);
        }
    }, [statusData, onStatusChange, setIsFormValid]);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <TelegramIcon />
                <div className={`${styles.message} ${styles[messageType]}`}>
                    {isPolling && <Loader />}
                    <span className={styles.statusMessageText}>{statusMessage}</span>
                </div>
            </div>
        </div>
    );
};

export default Step9;
