import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'app/store';
import { InputUI } from 'components/ui/InputUI/InputUI';
import { InputTypeEnum } from 'shared/models';
import TelegramIcon from 'assets/icons/TelegramIcon';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';

import styles from './RecoveryPassword.module.scss'
import LoadingOverlay from 'components/ui/LoadingOverlay/LoadingOverlay';
import { Modal } from 'components/ui/Modal';
import { useRecoveryPasswordMutation } from 'services/api';

const RecoveryPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [noExistEmailError, setNoExistEmailError] = useState(false);
  const [serverError, setServerError] = useState(false); // Новое состояние
  // console.log("🚀 ~ RecoveryPassword ~ serverError:", serverError)
  // const isLoading = useSelector(
  //   (state: any) => state.recoveryPassword?.isLoading
  // );
  const [loading, setLoading] = useState(false);
  // console.log("🚀 ~ RecoveryPassword ~ loading:", loading)
  const [recoveryPassword] = useRecoveryPasswordMutation();

  const handleBtnClick = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    const userData = { email };

    try {
      let result: any;

      // Проверка корректности email
      if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        setEmailError(true);
        setLoading(false);
        return;
      } else {
        setEmailError(false);
      }

      // Отправка запроса, если email корректен
      if (email && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        result = await recoveryPassword(userData);
        // console.log("🚀 ~ handleBtnClick ~ result:", result)
      }

      // Проверка наличия сообщения в ответе
      const resultMessage = result?.payload?.message;

      if (resultMessage && resultMessage.includes('не существует')) {
        setNoExistEmailError(true);
      } else if (result?.payload) {
        // Если результат успешный, перенаправляем
        setNoExistEmailError(false);
        navigate('/recovery-password-check');
      } else {
        // Обработка случая, когда сервер не отвечает
        // setEmailError(true);
        setServerError(true);
      }
    } catch (error) {
      console.error("Ошибка восстановления пароля:", error);

      // Устанавливаем общую ошибку при сетевых проблемах
      // setEmailError(true);
      // Показать модалку при недоступности сервера
      setServerError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.recoveryContainer}>
      {loading && <LoadingOverlay />}
      <TelegramIcon />
      <div className={styles.recoverySection}>
        <div className={styles.recoveryContent}>
          <TextUI variant="h1" align='center'>Восстановление пароля</TextUI>
          <TextUI variant="bodyL" align='center'>Введите свой email и мы отправим вам инструкцию по сбросу пароля.</TextUI>
        </div>
        <InputUI
          field={{
            value: email,
            onChange: (e) => {
              setEmail(e.target.value);
              if (emailError || noExistEmailError) {
                setEmailError(false);
                setNoExistEmailError(false); // Сбрасываем ошибки при новом вводе
              }
            },
            onBlur: () => { },
          }}
          id="email"
          type={InputTypeEnum.Email}
          name="email"
          placeholder="ВВЕДИТЕ EMAIL"
          onBlur={() => { }}
          error={emailError || noExistEmailError ? 'Электронная почта не найдена' : undefined}
        />
        <Modal
          isOpen={serverError}
          onClose={() => setServerError(false)}
          message="Ошибка сервера"
        />
      </div>
      <ButtonUI label="Отправить" variant="main" onClick={handleBtnClick} disabled={!email} fullWidth />
    </div>
  );
};

export default RecoveryPassword;
