import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';

interface PrivateRouteProps {
    redirectTo?: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ redirectTo = '/login' }) => {
    const isAuth = useSelector((state: RootState) => state.login.isAuth);
    const isAuthChecked = useSelector((state: RootState) => state.login.isAuthChecked);

    // Если проверка авторизации ещё не завершена, не делаем редирект
    if (!isAuthChecked) {
        return null; // или можно показать лоадер
    }

    return isAuth ? <Outlet /> : <Navigate to={redirectTo} />;
};

export default PrivateRoute;
